import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import { Grid, useMediaQuery, useTheme, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';

const SESSION_TITLE = 'Your session is ready to be shared';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ mobileView }) => (mobileView ? '' : '760px')};
`;

const Title = styled.div`
  margin-bottom: ${({ mobileView }) => (mobileView ? '10px' : '20px')};
  font-size: 22px;
  font-weight: 900;
  width: 100%;
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  line-height: normal;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
`;
const StyledContent = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-family: Avenir;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff !important' : '#000 !important')};
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  width: fit-content;
  height: 30px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff !important' : '#000 !important')};

  .MuiSvgIcon-root {
    height: 30px !important;
  }

  &.MuiSvgIcon-root {
    height: 30px !important;
  }
`;

const ShareInviteView = ({
  inviteLink,
  handleCopyLink,
  handleSendEmail,
  colorToUse,
  isDarkModeEnabled,
  onBack,
  onCancel,
  isTooltipOpen,
  isMasterCalendar,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const handleCopyClick = () => {
    handleCopyLink();
  };

  const handleEmailClick = () => {
    handleSendEmail();
  };

  return (
    <MainContainer mobileView={mobileView}>
      <Header style={{ marginTop: '10px' }}>
        <Title style={{ textAlign: 'left' }} mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
          {SESSION_TITLE}
        </Title>
        <IconContainer>
          <StyledCloseIcon isDarkModeEnabled={isDarkModeEnabled} onClick={onCancel} />
        </IconContainer>
      </Header>

      <StyledContent isDarkModeEnabled={isDarkModeEnabled}>Paste this link in a message to invite them.</StyledContent>

      <Grid>
        <input
          type="text"
          value={inviteLink}
          readOnly
          style={{
            fontSize: '16px',
            fontFamily: 'Avenir',
            fontWeight: '350',
            lineHeight: '23px',
            letterSpacing: '0.15px',
            wordWrap: 'break-word',
            opacity: '0.87',
            color: isDarkModeEnabled ? 'white' : '#4A4A4A',
            backgroundColor: isDarkModeEnabled ? 'rgb(37, 39, 40)' : 'white',
            display: 'inline-flex',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            marginBottom: '10px',
            marginRight: '10px',
            width: mobileView ? '100%' : '75%',
          }}
        />

        <Tooltip title="Copied to clipboard" open={isTooltipOpen} arrow>
          <Button
            isDarkModeEnabled={isDarkModeEnabled ?? false}
            style={{
              width: mobileView && '100%',
              height: '100%',
              backgroundColor: colorToUse?.PrimaryColorCode,
              borderColor: colorToUse?.PrimaryColorCode,
              color: 'white',
            }}
            onClick={handleCopyClick}
          >
            Copy Invite Link
          </Button>
        </Tooltip>
        <StyledContent isDarkModeEnabled={isDarkModeEnabled} style={{ display: 'flex', justifyContent: 'center' }}>
          Or
        </StyledContent>
        <Button
          isDarkModeEnabled={isDarkModeEnabled ?? false}
          style={{
            width: '100%',
            marginBottom: '20px',
            backgroundColor: colorToUse?.PrimaryColorCode,
            borderColor: colorToUse?.PrimaryColorCode,
            color: 'white',
            padding: '12px',
          }}
          onClick={handleEmailClick}
        >
          Send Invite via Email
          <Tooltip
            title={`You can edit this email later in the Emails & SMS tab${!isMasterCalendar ? ' above' : ''}.`}
            arrow
          >
            <InfoIcon style={{ marginLeft: '10px', marginBottom: '5px', width: '20px', height: '20px' }} htmlColor="" />
          </Tooltip>
        </Button>
      </Grid>
    </MainContainer>
  );
};

ShareInviteView.propTypes = {
  handleCopyLink: PropTypes.func.isRequired,
  handleSendEmail: PropTypes.func.isRequired,
  onCancel: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  isDarkModeEnabled: PropTypes.bool,
  inviteLink: PropTypes.string.isRequired,
  isTooltipOpen: PropTypes.bool.isRequired,
  isMasterCalendar: PropTypes.bool.isRequired,
  colorToUse: PropTypes.shape({
    PrimaryColorCode: PropTypes.string.isRequired,
    AccentColorCode: PropTypes.string.isRequired,
  }).isRequired,
};

ShareInviteView.defaultProps = {
  isDarkModeEnabled: false,
};

export default ShareInviteView;
