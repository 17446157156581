import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'utils/styles';
import {
  useMediaQuery,
  useTheme,
  Grid,
  Radio,
  TextField,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  InputLabel,
} from '@material-ui/core';
import { getThemedColors, createContract, getAllContracts, getSingleContract } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import CreateCustomWaiver from 'pages/CreateContribution/components/CreateCustomWaiver';
import { StyleLabelSignature } from 'pages/CreateContribution/style';
import useAccount from 'hooks/useAccount';
import Loader from 'components/UI/Loader';

export const StyledSlotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
`;

export const StyledTimeSlots = styled.div`
  display: inline-block;
  width: 100px;
  height: 35px;
  border: 1px solid #fafafa;
  border-radius: 8px;
  margin: ${({ margin }) => (margin ? margin : '5px')};
  color: darkgrey;
  background-color: #fffff;

  &:hover {
    cursor: pointer;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
      width: 80px;
  `}

  ${({ isSelected, colorForSelected }) =>
    isSelected &&
    `
    background-color: ${colorForSelected};
    border-color: ${colorForSelected};
    color: white;
  `}
  ${({ isSelected }) =>
    !isSelected &&
    `
    background-color: white;
  `}

  ${({ isBooked }) =>
    isBooked &&
    `
    color: ${colors.stroke};
    
    &:hover {
      cursor: default;
    }
  `}
`;
export const StyledTimeSlotValue = styled.div`
  font-weight: 500;
  font-size: 13px;
  margin: 7px 0;
  text-align: center;
`;

const StyledButton = styled.button`
  color: ${({ color }) => color};
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;

const FieldLabel = styled.div`
  font-family: 'Avenir';
  font-size: 16px;
  font-weight: 500;
  line-height: 24.8px;
  text-align: left;
  ${({ textColor }) => textColor && `color: ${textColor};`};
`;

const FieldTitle = styled.div`
  font-family: 'Avenir';
  font-size: 20px;
  font-weight: 800;
  line-height: 24.8px;
  text-align: left;
  ${({ textColor }) => textColor && `color: ${textColor};`};
`;

const OptionsContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding-left: 20px;
  width: 100%;
`;

const RadioSpan = styled.span`
  font-family: 'Avenir';
  font-size: 18px;
  font-weight: 800;
  line-height: 28.12px;
  text-align: left;
`;

const DescriptionSpan = styled.span`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  text-align: left;
  margin-left: 20px;
`;

const StyledRadio = styled(Radio)`
  .MuiSvgIcon-root {
    height: 20px !important;
  }
`;

const CustomStyleLabelSignature = styled(StyleLabelSignature)`
  .MuiSvgIcon-root {
    height: 20px !important;
  }

  svg {
    height: 24px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 3px;

  .MuiInput-underline:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px;
  }

  .MuiInput-root {
    border-radius: 4px;
    border: 1px solid #dfe3e4;
    padding: 0px 8px 0px 8px;
    height: 40px;
    margin-top: 0px;
  }
`;

const StyledSelect = styled(Select)`
  position: relative;
  border-radius: 0.375rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  overflow: hidden;

  &:before,
  &:after,
  &:hover:not(.Mui-disabled):before {
    border-bottom: 0px !important;
  }

  .MuiSelect-select {
    padding: 0.63rem 0.95rem;
  }

  .MuiSelect-icon {
    color: ${({ iconColor }) => iconColor};
  }
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterContainer = styled.div`
  margin-top: auto;
`;

const initialWaiverData = {
  formName: '',
  clientName: '',
  clientSignature: '',
  description: '',
  completeDescription: '',
  templateId: null,
  saveTemplate: false,
  templatesList: [],
};

const InviteTypeView = React.memo(
  ({
    onClose,
    onSubmit,
    isAuto,
    setIsAuto,
    isDarkModeEnabled,
    colorToUse,
    paymentInfo,
    autoAddSelectOptions,
    selectOptions,
    formik,
    meetingForm,
    setMeetingForm,
    selectedClient,
    isExistingClient,
    activeContribution,
    sendingInvite,
  }) => {
    const currentContribution = useContribution();
    const { user } = useAccount();
    const [isCustomWaiverAcitve, setIsCustomWaiverAcitve] = useState(activeContribution?.customWaiverId);
    const [isElectronicSignatureActive, setIsElectronicSignatureActive] = useState(activeContribution?.customWaiverId);
    const [customWaiverId, setCustomWaiverId] = useState(activeContribution.customWaiverId);
    const [waiverData, setWaiverData] = useState(initialWaiverData);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [allContractsFetched, setallContractsFetched] = useState([]);
    const [templateChanged, settemplateChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openCustomWaiver, setOpenCustomWaiver] = useState(false);
    const [tempData, setTempData] = useState({});
    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const { newThemedTextColor, newThemedBackgroundColor } = getThemedColors(currentContribution);

    useEffect(() => {
      if (isAuto) {
        const selected = autoAddSelectOptions.find(option => option.value === 'Credit') ?? autoAddSelectOptions[0];
        formik.setFieldValue('price', selected);
      } else {
        const defaultOption =
          selectOptions.find(option => option.price !== 'Free' && option.price !== 0) ||
          selectOptions.find(option => option.value === 'Credit') ||
          selectOptions[0];
        formik.setFieldValue('price', defaultOption);
      }
    }, [isAuto]);

    const onConfirm = async () => {
      onSubmit();
    };

    const isFormMatched = value => {
      const index = waiverData.templatesList.findIndex(template => template.value === value.templateId);
      return index;
    };

    const addNewFormTemplate = async finalValues => {
      finalValues = {
        ...finalValues,
        isSelfBooked: true,
        clientEmail: isExistingClient
          ? meetingForm?.client?.clientEmail || selectedClient?.clientEmail
          : formik?.values?.email,
        contributionId: activeContribution.id,
      };
      let tempWaiverData = { ...waiverData };
      let contribData = { ...activeContribution };
      createContract(finalValues).then(data => {
        tempWaiverData = {
          ...tempWaiverData,
          formName: data?.formName,
          formDescription: data?.formDescription,
          formText: data?.formText,
          description: data?.formDescription,
          templateId: data.id,
          id: data.id,
          completeDescription: data?.formText,
        };
        templateOptions.push({
          title: data.formName,
          value: data.id,
        });
        contribData = {
          ...contribData,
          customWaiverId: tempWaiverData.templateId,
        };
      });
      setOpenCustomWaiver(false);
    };

    const getSingleContractApi = async () => {
      setLoading(true);
      try {
        getSingleContract(
          activeContribution?.id,
          !isExistingClient ? selectedClient.clientEmail : formik?.values?.email,
        ).then(data => {
          setTimeout(() => {
            setWaiverData({
              ...waiverData,
              formName: data?.formName,
              formDescription: data?.formDescription,
              formText: data?.formText,
              description: data?.formDescription,
              templateId: data.id,
              id: data.id,
              completeDescription: data?.formText,
            });
          }, 2000);
        });
      } catch (e) {
        setLoading(false);
      }
    };

    const showCustomWaiver = async () => {
      setIsCustomWaiverAcitve(true);
      setIsElectronicSignatureActive(true);
      getSingleContractApi().then(() => {
        setOpenCustomWaiver(!openCustomWaiver);
      });
      try {
        await getAllContracts({ coachId: user.id }).then(res => {
          const activeWaiver = res.find(option => option.id === activeContribution.customWaiverId);
          const tempTemplateOptions = res?.map(option => {
            return {
              title: option.formName,
              formName: option.formName,
              formDescription: option.formDescription,
              formText: option.formText,
              value: option.id,
            };
          });
          setTemplateOptions(tempTemplateOptions);
          if (activeWaiver) {
            setWaiverData({
              ...waiverData,
              formName: activeWaiver?.formName,
              description: activeWaiver?.formDescription,
              completeDescription: activeWaiver?.formText,
              templateId: activeWaiver?.id,
              id: activeWaiver?.id,
              saveTemplate: false,
              templatesList: [...tempTemplateOptions],
            });
            setTemplateOptions([...tempTemplateOptions]);
            setOpenCustomWaiver(true);
          } else {
            setWaiverData({
              ...waiverData,
              templatesList: [...templateOptions, ...tempTemplateOptions],
            });
            setallContractsFetched(tempTemplateOptions);
            setOpenCustomWaiver(true);
          }
        });
      } catch (e) {
        setOpenCustomWaiver(true);
      }
    };

    const onSubmitWaiverForm = values => {
      let tempWaiverData = waiverData;
      const finalValues = {
        templateId: values.id,
        formName: values.formName,
        formDescription: values.description,
        formText: values.completeDescription,
        coachId: user.id,
        isTemplate: values.saveTemplate,
      };
      const isMatched = isFormMatched(values);
      if (isMatched !== -1) {
        if (
          `${values?.formName?.toLowerCase()}` !==
          `${tempWaiverData?.templatesList[isMatched]?.formName?.toLowerCase()}`
        ) {
          addNewFormTemplate(finalValues);
        } else {
          setTempData(finalValues);
          setConfirmationPopup(true);
        }
      }
      if (isMatched === -1) {
        addNewFormTemplate(finalValues);
      }
      setTempData(finalValues);
      setIsCustomWaiverAcitve(true);
      setIsElectronicSignatureActive(true);
    };

    return (
      <>
        {sendingInvite && <Loader zIndex={1350} />}
        <div
          className="one-one-session-slots-card"
          style={{
            backgroundColor: '#2d2f31',
            marginTop: '0px',
            padding: '0px 0px 0px 15px',
            justifyContent: 'space-between',
            minHeight: '60vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MainContainer>
            <div style={{ display: 'flex' }}>
              <p className="booking-title" style={{ color: colorToUse?.AccentColorCode }}>
                Select Invite Type
              </p>
            </div>

            <Grid container style={{ flexDirection: 'column', gap: '5px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }} className="radio-icon-style">
                <FormControlLabel
                  control={
                    <StyledRadio
                      color="primary"
                      checked={isAuto}
                      style={{ color: colorToUse?.AccentColorCode }}
                      onChange={() => {
                        formik.setErrors({});
                        setIsAuto(true);
                        formik.setErrors({});
                        formik.setTouched({ price: false });
                      }}
                    />
                  }
                  label={<RadioSpan>Auto Add 1:1 Session</RadioSpan>}
                />
                <DescriptionSpan>
                  Automatically adds and confirms the session in both your and your client’s account and calendar. Works
                  for free sessions or existing credits. (A temporary account will be created for new clients, which
                  they can activate via email)
                </DescriptionSpan>
              </div>
              {isAuto && (
                <OptionsContainer>
                  <StyledFormControl
                    fullWidth
                    className={isDarkModeEnabled ? 'price-select-popup-dark' : 'price-select-popup'}
                    style={{ position: 'relative' }}
                  >
                    <FieldLabel textColor={colorToUse?.AccentColorCode}>Select Booking Method</FieldLabel>
                    <div>
                      {formik.values.price === '' && (
                        <InputLabel
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: '10px',
                            marginTop: '26px',
                            fontSize: '16px',
                            marginBottom: 0,
                            color: '#a2a2a2',
                          }}
                          shrink={formik.values.price !== ''}
                          htmlFor="my-select"
                        >
                          Select Booking Method
                        </InputLabel>
                      )}
                    </div>
                    <StyledSelect
                      native
                      iconColor={isDarkModeEnabled ? 'white' : 'black'}
                      value={formik.values.price?.title || ''}
                      error={formik.errors.price?.title && true}
                      id="standard-full-width"
                      placeholder="Select Booking Method"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            background: isDarkModeEnabled ? '#696969' : 'white',
                            color: isDarkModeEnabled ? 'white' : 'black',
                          },
                        },
                      }}
                      style={{
                        backgroundColor: isDarkModeEnabled ? newThemedBackgroundColor : 'white',
                        color: isDarkModeEnabled ? newThemedTextColor : 'black',
                      }}
                      onChange={({ target: { value } }) => {
                        const val =
                          autoAddSelectOptions?.find(options => options.title === value) ?? autoAddSelectOptions[0];
                        formik.setFieldValue('price', val);
                      }}
                    >
                      {autoAddSelectOptions?.map(option => (
                        <option
                          value={option.title}
                          style={{
                            backgroundColor: isDarkModeEnabled ? '#696969' : 'white',
                            color: isDarkModeEnabled ? 'white' : 'black',
                          }}
                        >
                          {option.title}
                        </option>
                      ))}
                    </StyledSelect>
                    {formik?.touched?.price && formik?.errors?.price && (
                      <CommonErrorMessage message={formik?.errors?.price} align="left" color="red" />
                    )}{' '}
                  </StyledFormControl>
                </OptionsContainer>
              )}
            </Grid>
            <Grid container style={{ marginTop: '16px', flexDirection: 'column', gap: '5px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }} className="radio-icon-style">
                <FormControlLabel
                  control={
                    <StyledRadio
                      disabled={
                        paymentInfo?.paymentOptions?.length === 1 &&
                        paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')
                      }
                      style={{ color: colorToUse?.AccentColorCode }}
                      color="primary"
                      checked={!isAuto}
                      onChange={() => {
                        if (
                          paymentInfo?.paymentOptions?.length === 1 &&
                          paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')
                        ) {
                        } else {
                          formik.setErrors({});
                          setIsAuto(false);
                        }
                        setMeetingForm({
                          ...meetingForm,
                          client: '',
                        });
                      }}
                    />
                  }
                  label={<RadioSpan>Send 1:1 Invite (Approval Required)</RadioSpan>}
                />
                <DescriptionSpan>
                  Sends an invite, creating a pending session in your account. The session is added to the client’s
                  account and calendar only after they confirm. For paid sessions, payment is prompted upon acceptance
                </DescriptionSpan>
              </div>
              {!isAuto && (
                <OptionsContainer>
                  <StyledFormControl
                    fullWidth
                    className={isDarkModeEnabled ? 'price-select-popup-dark' : 'price-select-popup'}
                    style={{ position: 'relative' }}
                  >
                    <FieldLabel textColor={colorToUse?.AccentColorCode}>Select Price</FieldLabel>
                    <div>
                      {formik.values.price === '' && (
                        <InputLabel
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: '10px',
                            marginTop: '26px',
                            fontSize: '16px',
                            marginBottom: 0,
                            color: '#a2a2a2',
                          }}
                          shrink={formik.values.price !== ''}
                          htmlFor="my-select"
                        >
                          Select Price
                        </InputLabel>
                      )}
                    </div>
                    <StyledSelect
                      native
                      iconColor={isDarkModeEnabled ? 'white' : 'black'}
                      value={formik.values.price?.title || ''}
                      error={formik.errors.price?.title && true}
                      id="standard-full-width"
                      placeholder="Select Price"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            background: isDarkModeEnabled ? '#696969' : 'white',
                            color: isDarkModeEnabled ? 'white' : 'black',
                          },
                        },
                      }}
                      style={{
                        backgroundColor: isDarkModeEnabled ? newThemedBackgroundColor : 'white',
                        color: isDarkModeEnabled ? newThemedTextColor : 'black',
                      }}
                      onChange={({ target: { value } }) => {
                        const val = selectOptions?.find(options => options.title === value) ?? selectOptions[0];
                        formik.setFieldValue('price', val);
                      }}
                    >
                      {selectOptions?.map(option => (
                        <option
                          value={option.title}
                          style={{
                            backgroundColor: isDarkModeEnabled ? '#696969' : 'white',
                            color: isDarkModeEnabled ? 'white' : 'black',
                          }}
                        >
                          {option.title}
                        </option>
                      ))}
                    </StyledSelect>
                    {formik?.touched?.price && formik?.errors?.price && (
                      <CommonErrorMessage message={formik?.errors?.price} align="left" color="red" />
                    )}{' '}
                  </StyledFormControl>
                </OptionsContainer>
              )}
            </Grid>

            <OptionsContainer style={{ marginTop: '16px', paddingLeft: '0px' }}>
              <FieldTitle textColor={colorToUse?.AccentColorCode}>Session Name</FieldTitle>
              <StyledTextField
                className={
                  isDarkModeEnabled ? 'darkModeEnabled coach-client-name-margins' : 'coach-client-name-margins'
                }
                id="standard-full-width"
                margin="0px"
                fullWidth
                onChange={e => {
                  formik.setFieldValue('coachSessionTitle', e.target.value);
                  formik.setTouched({ price: false });
                }}
                value={formik.values.coachSessionTitle}
                placeholder="[Service Name] , [Client Name]"
              />
            </OptionsContainer>
            {activeContribution?.customWaiverId != null && (
              <CustomStyleLabelSignature>
                <Checkbox
                  style={{ padding: '8px 0px' }}
                  id="create-contribution-agreement"
                  color="primary"
                  checked={isCustomWaiverAcitve}
                  onChange={e => {
                    setIsCustomWaiverAcitve(!isCustomWaiverAcitve);
                    setIsElectronicSignatureActive(!isElectronicSignatureActive);
                    showCustomWaiver();
                  }}
                />
                <p
                  style={{ display: 'inline', margin: '0px', fontSize: '16px' }}
                  onClick={() => {
                    showCustomWaiver();
                  }}
                >
                  Use custom contract -{' '}
                  <p
                    style={{
                      display: 'inline',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      fontSize: '16px',
                      fontWeight: '500',
                    }}
                  >
                    Edit contract
                  </p>
                </p>
              </CustomStyleLabelSignature>
            )}
          </MainContainer>
          <FooterContainer>
            <div className="easy-booking-slot-buttons" style={{ margin: '20px 0px' }}>
              <StyledButton
                backgroundColor="white"
                borderColor="black"
                color="black"
                isDarkModeEnabled={isDarkModeEnabled ?? false}
                onClick={onClose}
              >
                Back
              </StyledButton>
              <StyledButton
                backgroundColor={colorToUse?.PrimaryColorCode}
                borderColor={colorToUse?.PrimaryColorCode}
                color="white"
                isDarkModeEnabled={isDarkModeEnabled ?? false}
                onClick={onConfirm}
              >
                Confirm
              </StyledButton>
            </div>
          </FooterContainer>
        </div>
        {openCustomWaiver && (
          <CreateCustomWaiver
            isOpen={openCustomWaiver}
            onSubmit={values => onSubmitWaiverForm(values)}
            contribution={activeContribution}
            waiverData={waiverData}
            isDarkModeAllowed={true}
            setCustomWaiverId={setCustomWaiverId}
            settemplateChanged={settemplateChanged}
            onCancel={() => setOpenCustomWaiver(false)}
            email={isExistingClient ? selectedClient.clientEmail : formik?.values?.email}
            hideSaveButton={true}
            allContractsFetched={allContractsFetched}
            isCreateContribution={false}
            isCoachorPartner={true}
          />
        )}
      </>
    );
  },
);

InviteTypeView.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isDarkModeEnabled: PropTypes.bool.isRequired,
  isAuto: PropTypes.bool.isRequired,
  setIsAuto: PropTypes.func.isRequired,
  colorToUse: PropTypes.shape({
    PrimaryColorCode: PropTypes.string.isRequired,
    AccentColorCode: PropTypes.string.isRequired,
  }).isRequired,
  filterClient: PropTypes.func.isRequired,
  sendingInvite: PropTypes.bool.isRequired,
};

InviteTypeView.defaultProps = {};

export default InviteTypeView;
