import { useContribution } from 'pages/ContributionView/hooks';
import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Avatar, Box } from '@material-ui/core';
import { determineColorToUse } from 'services/contributions.service';
import { getIcalFileLinkOneToOneSession } from 'services/purchase.service';
import pluralize from 'pluralize';
import { getThemedColors } from 'services/contributions.service';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { BiCalendar } from 'react-icons/bi';
import { MenuItem, useTheme, useMediaQuery, ClickAwayListener, Tooltip } from '@material-ui/core';
import useAccount from 'hooks/useAccount';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ExistingUserGuestContributionAddModal from './ExistingUserGuestContributionAddModal';
import Button from 'components/FormUI/Button';
import { setRescheduleFormerSessionDetail } from 'actions/contributions';
import useRouter from 'hooks/useRouter';
import { lightOrDark } from 'utils/utils';
import { UserRoles } from 'helpers/constants';

import CloseIcon from '@material-ui/icons/Close';
import getInitials from 'utils/getInitials';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const StyledPContainer = styled.div`
  ${({ mobileView }) => mobileView && css``}
`;
const AvatarComponentForSession = styled(Avatar)`
  width: 55px;
  height: 55px;
`;
const StyledP = styled.div`
  color: ${({ isDarkModeEnabled, newThemedTextColor }) => (isDarkModeEnabled ? newThemedTextColor : '')};
`;
const StyledContainerBox = styled.div`
  display: flex;
  min-width: ${({ mobileView }) => (mobileView ? '100%' : '49.3125rem')};
  min-height: 34.625rem;
  max-width: 54.8%;
  max-height: 54.4%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.25rem;
  background: #fff;

  ${({ isModal }) =>
    !isModal
      ? css`
          border: 1px solid var(--Cohere-Greys-Background-Grey, #f5f5f5);
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        `
      : ''}
`;
const CalendarContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${({ mobileView }) =>
    mobileView
      ? css`
          width: calc(100% + 20px);
        `
      : ''}
`;

const CalendarMenu = styled.div`
  width: 220px;
  background-color: white;
  color: black;
  box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  position: absolute;
  ${({ mobileView, isModal }) =>
    mobileView && isModal
      ? css`
          width: 160px;
          right: 10px;
          top: 20px;
        `
      : !mobileView && !isModal
      ? css`
          width: 200px;
          right: -25px;
          top: 20px;
        `
      : mobileView && !isModal
      ? css`
          width: 180px;
          margin-left: 0px;
          right: 0px;
          top: 20px;
        `
      : !mobileView && isModal
      ? css`
          width: 180px;
          right: -15px;
          top: 20px;
        `
      : css`
          top: 23px;
          right: -50px;
        `}
  z-index: 1000;
`;
const StyledImageWrapper = styled.div`
  display: flex;
  padding: 0.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: 4.2625rem;
  height: 4.2625rem;
  max-width: 4.2625rem;
  max-height: 4.2625rem;

  border-radius: 4.0625rem;
  border: 2px solid ${({ borderColor }) => borderColor || 'var(--Cohere-Secondary-Brown, #c9b382)'};

  > .img-container {
    height: 100%;
    width: 100%;
  }
`;
const StyledMessageContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  & > .main-message {
    color: rgba(0, 0, 0, 0.87);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  & > .sub-message {
    color: rgba(0, 0, 0, 0.87);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    width: ${({ isScheduleClientFlow }) => isScheduleClientFlow && '70%'};
    text-align: ${({ isScheduleClientFlow }) => isScheduleClientFlow && 'center'};
    white-space: ${({ isScheduleClientFlow }) => isScheduleClientFlow && 'pre-line'};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  position: absolute;
  top: 22px;
  right: ${({ isScheduleClientFlow }) => (isScheduleClientFlow ? '20px' : '36px')};
  width: 40px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-right: -15px;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: black')}
`;

const StyledTextAddCalendar = styled.span`
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.25rem; /* 142.857% */
`;

const StyledMeetingInfoBlock = styled.div`
  background: #f5f5f5;
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  background: #fff;

  & > .meeting-duration {
    color: rgba(0, 0, 0, 0.87);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  & > .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    &:nth-child(2) {
      .icon {
        margin-top: -2px;
      }
    }
    & > .info-row {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      > .icon {
        width: 1.5rem;
        height: 1.5rem;
      }
      > .text {
        color: rgba(0, 0, 0, 0.87);
        font-family: Avenir;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        text-transform: capitalize;
      }
      > .text.coach-name {
      }
      > .meeting-date-time {
      }
      > .meeting-location {
      }
    }
  }
  & > .actions {
  }
`;
const CoachIcon = ({ color = '#215C73' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M12.1471 5.88235C13.7038 5.88235 14.9706 7.14918 14.9706 8.70588C14.9706 10.2626 13.7038 11.5294 12.1471 11.5294C10.5904 11.5294 9.32353 10.2626 9.32353 8.70588C9.32353 7.14918 10.5904 5.88235 12.1471 5.88235ZM12.1471 4C9.54565 4 7.44118 6.10635 7.44118 8.70588C7.44118 11.3054 9.54565 13.4118 12.1471 13.4118C14.7485 13.4118 16.8529 11.3054 16.8529 8.70588C16.8529 6.10635 14.7485 4 12.1471 4ZM12.1471 16.2353C14.0492 16.2353 15.2539 16.9609 15.7075 17.5953C15.0647 17.84 13.8544 18.1176 12.1471 18.1176C10.2798 18.1176 9.12965 17.8306 8.55365 17.6075C8.98565 16.9346 10.2261 16.2353 12.1471 16.2353ZM12.1471 14.3529C8.61765 14.3529 6.5 16.2353 6.5 18.1176C6.5 19.0588 8.61765 20 12.1471 20C15.4581 20 17.7941 19.0588 17.7941 18.1176C17.7941 16.2353 15.5786 14.3529 12.1471 14.3529Z"
        fill={color}
      />
    </svg>
  );
};
const RescheduleIcon = ({ color = 'black' }) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66658 14.5L1.33325 11.1667L4.66658 7.83333L5.61659 8.76667L3.88325 10.5H13.9999V11.8333H3.88325L5.61659 13.5667L4.66658 14.5ZM11.3333 9.16667L10.3833 8.23333L12.1166 6.5H1.99992V5.16667H12.1166L10.3833 3.43333L11.3333 2.5L14.6666 5.83333L11.3333 9.16667Z"
        fill={color}
      />
    </svg>
  );
};

const DateCalendarIcon = ({ color = '#215C73' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M12.7 14.8C12.14 14.8 11.6667 14.6067 11.28 14.22C10.8933 13.8333 10.7 13.36 10.7 12.8C10.7 12.24 10.8933 11.7667 11.28 11.38C11.6667 10.9933 12.14 10.8 12.7 10.8C13.26 10.8 13.7333 10.9933 14.12 11.38C14.5067 11.7667 14.7 12.24 14.7 12.8C14.7 13.36 14.5067 13.8333 14.12 14.22C13.7333 14.6067 13.26 14.8 12.7 14.8ZM5.1 18C4.66 18 4.28347 17.8435 3.9704 17.5304C3.65733 17.2173 3.50053 16.8405 3.5 16.4V5.2C3.5 4.76 3.6568 4.38347 3.9704 4.0704C4.284 3.75733 4.66053 3.60053 5.1 3.6H5.9V2H7.5V3.6H13.9V2H15.5V3.6H16.3C16.74 3.6 17.1168 3.7568 17.4304 4.0704C17.744 4.384 17.9005 4.76053 17.9 5.2V16.4C17.9 16.84 17.7435 17.2168 17.4304 17.5304C17.1173 17.844 16.7405 18.0005 16.3 18H5.1ZM5.1 16.4H16.3V8.4H5.1V16.4ZM5.1 6.8H16.3V5.2H5.1V6.8Z"
        fill={color}
      />
    </svg>
  );
};
export const GlobeIcon = ({ color = '#215C73' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.5 12C4.5 7.5816 8.0816 4 12.5 4C16.9184 4 20.5 7.5816 20.5 12C20.5 16.4184 16.9184 20 12.5 20C8.0816 20 4.5 16.4184 4.5 12ZM6.1 12C6.1 13.6974 6.77428 15.3253 7.97452 16.5255C9.17475 17.7257 10.8026 18.4 12.5 18.4C14.1974 18.4 15.8253 17.7257 17.0255 16.5255C18.2257 15.3253 18.9 13.6974 18.9 12C18.9 10.3026 18.2257 8.67475 17.0255 7.47452C15.8253 6.27428 14.1974 5.6 12.5 5.6C10.8026 5.6 9.17475 6.27428 7.97452 7.47452C6.77428 8.67475 6.1 10.3026 6.1 12ZM9.5016 10.7584C9.696 10.352 10.016 9.2 10.2472 9.2C10.4776 9.2 10.3936 9.7784 10.6 9.8288C10.7736 9.8712 10.64 9.5704 11.1224 9.5168C11.6048 9.4632 12.1304 9.7584 12.1304 9.7584C12.1304 9.7584 12.7 9.9 13.2256 9.7584C13.2256 9.7584 12.948 9.3376 13.288 9.2C13.6272 9.0624 14.0896 9.5712 14.1064 9.9C14.1232 10.2288 13.3512 10.4496 13.3512 10.4496L14.1064 10.9032C14.1064 10.9032 14.328 10.2008 14.8 10.1936C15.22 10.1856 15.7464 10.9536 15.5 11.3C15.2536 11.6464 15.0952 11.408 15.0952 11.408C15.0952 11.408 14.3432 12.1696 14.1064 12.3064C13.8704 12.4424 13.54 12.3064 13.54 12.3064C13.54 12.3064 13.4 12.5656 13.54 12.6848C13.824 12.9776 14.5616 13.2688 14.5616 13.2688C14.5616 13.2688 16.8296 13.6376 16.9 14.1C16.9704 14.5624 15.0952 16.9 14.8 16.9H14.1C13.8144 16.396 14.5616 14.7848 14.5616 14.7848C14.5616 14.7848 14.1776 14.3592 14.1 14.1C14.0232 13.8408 14.2488 13.3848 14.2488 13.3848L13.2256 12.9136C13.2256 12.9136 12.6816 12.9136 12.412 12.7C12.1424 12.4864 12 11.3 12 11.3L11.024 10.5848C11.024 10.5848 10.176 11.6616 9.9 11.524C9.6232 11.3864 9.308 11.164 9.5016 10.7584ZM14.7576 9.08C14.0824 9.08 12.4576 8.2208 12.7304 7.76C12.7304 7.0992 14.4384 7.1984 14.8048 7.1984C15.1712 7.1984 16.0688 7.4816 16.5672 7.8784C17.0656 8.2744 16.3568 8.8408 16.1096 9.08C15.8616 9.32 15.4336 9.08 14.7576 9.08Z"
        fill={color}
      />
    </svg>
  );
};

const SessionBookingThankyou = ({
  setRescheduleSession,
  rescheduleSession,
  isModal = false,
  isAutoAddFlow = false,
  isScheduleClientFlow = false,
  modalTitle = null,
  modalSubtitle = null,
  clientName = null,
  timezone = null,
  timeSlot = null,
  selectedSlot = null,
  isMasterCalendar = false,
  handleCancel,
  scheduleClientAvatarUrl = null,
}) => {
  const { currentRole } = useAccount();
  const isCohealer = currentRole === UserRoles.cohealer;
  const easyBookingThankYou = useSelector(state => state.easyBookingThankYou);
  const { participantInfo } = useSelector(state => state.contributions);
  const contribution = useContribution();
  const theme = useTheme();
  const { query } = useRouter();
  const reschedulingSession = query.code === 'GuestUserReschedule';
  const dispatch = useDispatch();
  const { AccentColorCode = '', PrimaryColorCode = '' } = determineColorToUse(isMasterCalendar ? null : contribution);
  let colorToUse = determineColorToUse(isMasterCalendar ? null : contribution);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const [isVisibleCalendarDropdown, setIsVisibleCalendarDropdown] = useState(false);
  const [thanksYouPageData, setThanksYouPageData] = useState({});
  const { serviceProviderName = '', durations, coachAvatarUrl = '', isDarkModeEnabled = false } = contribution;
  const duration = durations && durations?.length ? durations[0] : 0;
  const dropdownRef = useRef(null);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { newThemedBackgroundColor, newThemedCardColor, newThemedTextColor } = getThemedColors(
    isMasterCalendar ? null : contribution,
  );
  const themedColor = newThemedTextColor;
  const [links, setLinks] = useState({});
  const { user } = useAccount();
  const { showModal } = useSelector(state => state.easyBookingThankYou);
  const [openModal, setOpenModal] = useState(user?.isSecondaryExisting && !user?.isSecondaryAccount && showModal);
  const availableToReschedule = isCohealer
    ? true
    : moment(easyBookingThankYou?.selectedSlot?.start).isAfter(moment().add(24, 'h'));
  const title = modalTitle ?? 'The session is scheduled.';
  const subTitle = modalSubtitle ?? 'A calendar invitation has been sent to your email.';

  useEffect(() => {
    setOpenModal(user?.isSecondaryExisting && !user?.isSecondaryAccount && showModal);
  }, [user]);

  useEffect(() => {
    dispatch(
      setRescheduleFormerSessionDetail({
        timezone: easyBookingThankYou?.timeZoneThankYou,
        timeSlot: `${moment(easyBookingThankYou?.selectedSlot?.start).format('h:mmA')} - ${moment(
          easyBookingThankYou?.selectedSlot?.end,
        ).format('h:mmA')}, ${moment(easyBookingThankYou?.selectedDate).format('dddd, MMMM DD, YYYY')}`,
        selectedSlot: easyBookingThankYou?.selectedSlot,
      }),
    );
    setThanksYouPageData({
      timezone: isScheduleClientFlow ? timezone : easyBookingThankYou?.timeZoneThankYou,
      timeSlot: isScheduleClientFlow
        ? timeSlot
        : `${moment(easyBookingThankYou?.selectedSlot?.start).format('h:mmA')} - ${moment(
            easyBookingThankYou?.selectedSlot?.end,
          ).format('h:mmA')}, ${moment(easyBookingThankYou?.selectedDate).format('dddd, MMMM DD, YYYY')}`,
      selectedSlot: isScheduleClientFlow ? selectedSlot : easyBookingThankYou?.selectedSlot,
    });
  }, [user, easyBookingThankYou]);

  const getIcalLinks = async () => {
    const icalLinks = await getIcalFileLinkOneToOneSession({
      contributionId: contribution.id,
      availabilityTimeId: thanksYouPageData?.selectedSlot?.id,
      scheduledClientName: clientName,
    });
    setLinks(icalLinks);
  };

  const handleClickEvent = type => {
    const link = links[type];
    if (link) {
      window.open(link, '_blank');
    }
  };

  useEffect(() => {
    if (thanksYouPageData?.selectedSlot && thanksYouPageData?.selectedSlot.id) {
      if (isScheduleClientFlow && !isAutoAddFlow) return;
      getIcalLinks();
    }
  }, [thanksYouPageData?.selectedSlot]);

  return (
    <>
      <StyledContainer>
        <StyledContainerBox
          mobileView={mobileView}
          isModal={isModal}
          style={{
            ...(!isMasterCalendar &&
              isDarkModeEnabled && {
                backgroundColor: newThemedCardColor,
                border: isModal ? '1px solid transparent' : '1px solid #f5f5f550',
              }),
          }}
        >
          {isModal && (
            <HeaderContainer isScheduleClientFlow={isScheduleClientFlow}>
              <StyledCloseIcon
                isDarkThemeEnabled={!isMasterCalendar && isDarkModeEnabled}
                onClick={() => {
                  if (isModal && handleCancel) handleCancel();
                }}
              />
            </HeaderContainer>
          )}
          {isCohealer ? (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
              <StyledImageWrapper {...{ borderColor: PrimaryColorCode }}>
                <AvatarComponentForSession
                  className="mx-2"
                  alt={`${user.firstName} ${user.lastName}`}
                  src={contribution.coachAvatarUrl}
                  mobileView={mobileView}
                >
                  {`${serviceProviderName && getInitials(serviceProviderName)}`}
                </AvatarComponentForSession>
                {/* <Avatar className="img-container" src={coachAvatarUrl} alt="coach-profile-pic" /> */}
              </StyledImageWrapper>
              <RescheduleIcon color={newThemedTextColor} />
              <StyledImageWrapper {...{ borderColor: PrimaryColorCode }}>
                <AvatarComponentForSession
                  className="mx-2"
                  alt={`${user.firstName} ${user.lastName}`}
                  src={isScheduleClientFlow ? scheduleClientAvatarUrl : participantInfo.avatarUrl}
                  mobileView={mobileView}
                >
                  {`${
                    isScheduleClientFlow && clientName
                      ? getInitials(clientName)
                      : participantInfo.firstName
                      ? getInitials(`${participantInfo.firstName} ${participantInfo.lastName}`)
                      : ''
                  }`}
                </AvatarComponentForSession>
              </StyledImageWrapper>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
              <StyledImageWrapper {...{ borderColor: PrimaryColorCode }}>
                <AvatarComponentForSession
                  className="mx-2"
                  alt={`${user.firstName} ${user.lastName}`}
                  src={user.avatarUrl}
                  mobileView={mobileView}
                >
                  {`${user.firstName && getInitials(`${user.firstName} ${user.lastName}`)}`}
                </AvatarComponentForSession>
              </StyledImageWrapper>

              <RescheduleIcon color={newThemedTextColor} />
              <StyledImageWrapper {...{ borderColor: PrimaryColorCode }}>
                <AvatarComponentForSession
                  className="mx-2"
                  alt={`${user.firstName} ${user.lastName}`}
                  src={contribution.coachAvatarUrl}
                  mobileView={mobileView}
                >
                  {`${serviceProviderName && getInitials(serviceProviderName)}`}
                </AvatarComponentForSession>
                {/* <Avatar className="img-container" src={coachAvatarUrl} alt="coach-profile-pic" /> */}
              </StyledImageWrapper>
            </div>
          )}
          <StyledMessageContainer isScheduleClientFlow={isScheduleClientFlow}>
            <div className="main-message" style={{ color: newThemedTextColor }}>
              {title}
            </div>
            <div className="sub-message" style={{ color: newThemedTextColor }}>
              {subTitle}
            </div>
          </StyledMessageContainer>
          <StyledMeetingInfoBlock
            style={{
              ...(!isMasterCalendar &&
                isDarkModeEnabled && { backgroundColor: newThemedCardColor, border: '1px solid #f5f5f550' }),
            }}
          >
            <div className="meeting-duration" style={{ color: newThemedTextColor }}>
              {duration} {pluralize('Minute', duration)} Meeting
            </div>
            <div className="info">
              <div className="info-row">
                <div className="icon" style={{ height: '25px' }}>
                  <CoachIcon color={AccentColorCode} />
                </div>
                <div className="text coach-name" style={{ color: newThemedTextColor }}>
                  {isScheduleClientFlow ? clientName : serviceProviderName}
                </div>
              </div>
              <div className="info-row">
                <div className="icon" style={{ height: '22px' }}>
                  <DateCalendarIcon color={AccentColorCode} />
                </div>
                <div className="text meeting-date-time" style={{ color: newThemedTextColor }}>
                  {thanksYouPageData?.timeSlot}
                </div>
              </div>
              <div className="info-row">
                <div className="icon" style={{ height: '25px' }}>
                  <GlobeIcon color={AccentColorCode} />
                </div>
                <div className="text meeting-location" style={{ color: newThemedTextColor }}>
                  {thanksYouPageData?.timezone}
                </div>
              </div>
            </div>
            {!(isScheduleClientFlow && !isAutoAddFlow) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: '14px',
                }}
              >
                <StyledPContainer
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '0.25rem',
                    boxShadow: 'none',
                    padding: mobileView ? '0px 2px 0px 5px' : '10px 0px',
                    border: `1px solid ${PrimaryColorCode}`,
                    width: '100%',
                    justifyContent: 'center',
                    ...(!isMasterCalendar &&
                      isDarkModeEnabled &&
                      {
                        // backgroundColor: PrimaryColorCode,
                      }),
                  }}
                  {...{ mobileView }}
                >
                  <StyledP
                    newThemedTextColor={newThemedTextColor}
                    isDarkModeEnabled={!isMasterCalendar && isDarkModeEnabled}
                    ref={dropdownRef}
                    onClick={() => setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown)}
                    style={{
                      // width: '100%',
                      marginBottom: '0px',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      display: 'flex',
                      width: mobileView ? '' : '10rem',
                      ...(mobileView && { flexBasis: 'auto' }),
                    }}
                    className="course-live-session-time__label"
                  >
                    <BiCalendar
                      style={{
                        fontSize: 20,
                        color: !isMasterCalendar && isDarkModeEnabled ? newThemedTextColor : PrimaryColorCode,
                        marginTop: '-1px',
                        marginRight: '5px',
                      }}
                    />{' '}
                    <StyledTextAddCalendar
                      style={{
                        fontSize: mobileView ? '12px' : '14px',
                        color: PrimaryColorCode,
                        ...(!isMasterCalendar &&
                          isDarkModeEnabled && {
                            color: newThemedTextColor,
                          }),
                      }}
                    >
                      Add to Calendar
                    </StyledTextAddCalendar>{' '}
                    {!isVisibleCalendarDropdown ? (
                      <RiArrowDropDownLine
                        style={{
                          float: 'right',
                          fontSize: 20,
                          color: !isMasterCalendar && isDarkModeEnabled ? newThemedTextColor : PrimaryColorCode,
                          marginTop: '1px',
                        }}
                      />
                    ) : (
                      <RiArrowDropUpLine
                        style={{
                          float: 'right',
                          fontSize: 20,
                          color: !isMasterCalendar && isDarkModeEnabled ? newThemedTextColor : PrimaryColorCode,
                          marginTop: '1px',
                        }}
                      />
                    )}
                  </StyledP>
                  {isVisibleCalendarDropdown && (
                    <ClickAwayListener
                      onClickAway={() => {
                        setIsVisibleCalendarDropdown(false);
                      }}
                    >
                      <Box>
                        <CalendarContainer className="clickedOutSide" mobileView={mobileView}>
                          <CalendarMenu
                            newThemedTextColor={
                              !isMasterCalendar && isDarkModeEnabled ? newThemedTextColor : themedColor
                            }
                            className="clickedOutSide"
                            mobileView={mobileView}
                            isModal={isModal}
                          >
                            <MenuItem
                              onClick={() => handleClickEvent('iCalFileLink')}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                borderBottom: '0.5px solid gray',
                                fontSize: mobileView ? '12px' : '14px',
                                ...(!isMasterCalendar &&
                                  isDarkModeEnabled && {
                                    backgroundColor: newThemedBackgroundColor,
                                    color: newThemedTextColor,
                                  }),
                              }}
                            >
                              Download iCal
                            </MenuItem>
                            <MenuItem
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                fontSize: mobileView ? '12px' : '14px',
                                ...(!isMasterCalendar &&
                                  isDarkModeEnabled && {
                                    backgroundColor: newThemedBackgroundColor,
                                    color: newThemedTextColor,
                                  }),
                              }}
                              onClick={() => handleClickEvent('importLink')}
                            >
                              Add to Google Calendar
                            </MenuItem>
                          </CalendarMenu>
                        </CalendarContainer>
                      </Box>
                    </ClickAwayListener>
                  )}
                </StyledPContainer>
                <>
                  {!availableToReschedule ? (
                    <Tooltip title="Cannot reschedule within 24 hours of the session start time." arrow>
                      <div
                        style={{
                          position: 'relative',
                          padding: mobileView ? '10px 16px 10px 16px' : '10px 16px 10px 16px',
                          fontSize: mobileView ? '12px' : '14px',
                          fontWeight: 900,
                          borderRadius: 4,
                          color: '#9B9B9B',
                          backgroundColor: '#EDEDED',
                          width: '80%',
                          textAlign: 'center',
                        }}
                      >
                        Reschedule
                      </div>
                    </Tooltip>
                  ) : (
                    <Button
                      style={{
                        padding: mobileView ? '10px 16px 10px 16px' : '10px 16px 10px 16px',
                        fontSize: mobileView ? '12px' : '14px',
                        width: '80%',
                      }}
                      textColor={textColor}
                      backgroundColor={PrimaryColorCode}
                      autoWidth
                      onClick={() => {
                        setRescheduleSession(true);
                      }}
                    >
                      Reschedule
                    </Button>
                  )}
                </>
              </div>
            )}
          </StyledMeetingInfoBlock>
        </StyledContainerBox>
      </StyledContainer>
      {openModal && reschedulingSession === false && (
        <ExistingUserGuestContributionAddModal
          user={user}
          isOpen={openModal}
          setIsOpen={setOpenModal}
          PrimaryColorCode={PrimaryColorCode}
          newThemedTextColor={newThemedTextColor}
          newThemedBackgroundColor={newThemedBackgroundColor}
        />
      )}
    </>
  );
};

export default SessionBookingThankyou;
