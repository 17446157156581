import React, { useRef, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useAccount, useApplicationStatus, useRouter, useDefaultTimezone } from 'hooks';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTimeZones } from 'actions/timeZone';
import Grid from '@material-ui/core/Grid';
import { orderBy, uniqBy } from 'lodash';
import * as countryActions from 'actions/country';
import { TIMEZONES } from 'constants.js';
import { colors } from 'utils/styles';
import { getItem, removeItem } from 'services/localStorage.service';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { dataURLtoFile, getIpGlobal } from 'utils/utils';
import { darken } from 'polished';
import { TemplateType } from 'helpers/constants/templateType';
import { LabelText, SimpleText } from 'components/UI/Text/TextStyles';
import axiosInstance from 'utils/axiosInstance';
import Modal from 'components/UI/Modal';
import isEmpty from 'lodash/isEmpty';
import Loader from 'components/UI/Loader';
import { getTimePeriodsForAvailability, DATE_FORMATS } from 'utils/datesAndMoney';
import SignatureCanvas from 'react-signature-canvas';
import { useStyles } from '../PurchaseBlock/hooks/useStyles';
import { PhoneInput } from 'react-international-phone';
import Checkbox from '@material-ui/core/Checkbox';
import * as contributionService from 'services/contributions.service';
import {
  getClientContributionTimes,
  getCoachContributionTimes,
  getOneToOneSelfBookClient,
  getSingleContract,
  saveSignoffData,
  setSignedContract,
  updateTimeforSelfBook,
} from 'services/contributions.service';
import { makeStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { setEasyBookingData } from 'actions/easyBooking';
import CreateCustomWaiver from 'pages/CreateContribution/components/CreateCustomWaiver';
import EnrollmentForm from '../EnrollmentForm';
import BackSvg from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/icons/BackIcon';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { startIncrementDurationsOneToOne } from 'pages/CreateContribution/components/OneToOneForm';
import { ACCOUNT_FORM_FIELDS } from './PurchaseModal.constants';
import { editUserProfile } from 'services/user.service';
import {
  fetchCohealerContribution,
  setErrorPopUp,
  setGuestTimeZone,
  setRescheduleFormerSessionDetail,
  setRescheduleSessionTimeIdForGuestCheckout,
} from 'actions/contributions';
import { updateUser } from 'actions/update-user';
import { setEasyBookingThankYouData } from 'actions/easyBookingThankYou';
import { isMongoId } from 'validator';
import { UserRoles } from 'helpers/constants';

const StyledLink = styled(Link)`
  color: ${colors.lightBrown};
`;
const StyledSimpleText = styled(SimpleText)`
  margin: 25px 10px 25px 0px;
  display: inline-block;
`;
const StyledLabelText = styled(LabelText)`
  margin: 25px 0;
  display: inline-block;
`;

export const StyledSlotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
`;
const StyledSelectTz = styled(Select)`
  position: relative;
  border-radius: ${({ isModal }) => (isModal ? '0.375rem;' : '1.375rem;')};
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  .MuiSelect-select {
    padding: 0.63rem 0.95rem;
  }

  .MuiSelect-icon {
    color: ${({ iconColor }) => iconColor};
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  width: ${({ mobileView }) => (mobileView ? '100%' : '60%')};
  gap: 10px;
  &.phone-error {
    .react-international-phone-input {
      border-color: red;
    }
  }
  .react-international-phone-country-selector {
    .react-international-phone-country-selector-button {
      padding: 10px 6px;
      height: auto;
      border-radius: 10px;
    }
  }
  .react-international-phone-input {
    width: 100%;
    padding: 10px 10px;
    height: auto;
    border-radius: 10px;
    font-size: 14px;
  }
`;
export const StyledTimeSlots = styled.div`
  display: inline-block;
  width: 100px;
  height: 35px;
  border: 1px solid #fafafa;
  border-radius: 8px;
  margin: ${({ margin }) => (margin ? margin : '5px')};
  color: darkgrey;
  background-color: #fffff;

  &:hover {
    cursor: pointer;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
      width: 80px;
  `}

  ${({ isSelected, colorForSelected }) =>
    isSelected &&
    `
    background-color: ${colorForSelected};
    border-color: ${colorForSelected};
    color: white;
  `}
  ${({ isSelected }) =>
    !isSelected &&
    `
    background-color: white;
  `}

  ${({ isBooked }) =>
    isBooked &&
    `
    color: ${colors.stroke};
    
    &:hover {
      cursor: default;
    }
  `}
`;
export const StyledTimeSlotValue = styled.div`
  font-weight: 500;
  font-size: 13px;
  margin: 7px 0;
  text-align: center;
`;
const StyledError = styled.div`
  color: ${({ template, PrimaryColor }) =>
    template === TemplateType.TemplateOne
      ? PrimaryColor
        ? PrimaryColor
        : '#6999CA'
      : PrimaryColor
      ? PrimaryColor
      : '#D08ACD'};
  font-size: 14px;
  margin-top: 2px;
  padding-left: 10px;
`;
const StyledDiv = styled.span`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterContainer = styled.div`
  margin-top: auto;
`;
const StyledButton = styled.button`
  position: relative;
  padding: 10px 25px;
  font-size: 0.875rem;
  border-radius: 2px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  // min-width: 18rem;
  width: 80%;
  transition: background 0.2s;
  font-family: 'Avenir';
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 10px;
    `}

  ${({ variant, backgroundColor, activeTemplate }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return activeTemplate === TemplateType.TemplateOne
      ? css`
          background-color: #6999ca;
        `
      : css`
          background-color: #b78aea;
        `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor, activeTemplate }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      return activeTemplate === TemplateType.TemplateOne
        ? css`
            background-color: ${darken(0.05, '#6999CA')};
          `
        : css`
            background-color: ${darken(0.05, '#B78AEA')};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;
const StyledButtonPadded = styled.button`
  position: relative;
  padding: 10px 15px;
  font-size: 0.875rem;
  border-radius: 2px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  // min-width: 18rem;
  width: 80%;
  transition: background 0.2s;
  font-family: 'Avenir';
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 10px;
    `}

  ${({ variant, backgroundColor, activeTemplate }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return activeTemplate === TemplateType.TemplateOne
      ? css`
          background-color: #6999ca;
        `
      : css`
          background-color: #b78aea;
        `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor, activeTemplate }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      return activeTemplate === TemplateType.TemplateOne
        ? css`
            background-color: ${darken(0.05, '#6999CA')};
          `
        : css`
            background-color: ${darken(0.05, '#B78AEA')};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;
const StyledHeading = styled.div`
  font-family: 'Brandon Text';
  font-style: normal;
  font-size: 25px;
  font-weight: 800;
  justify-content: center;
  display: flex;
`;

// const StyledTitle = styled.div`
//   color: ${({ color }) => color || 'var(--Cohere-Primary-Blue, #215C73)'};
//   font-family: Avenir;
//   font-size: 1rem;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;
//   ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
// `;
const SlotsModalForPaymentCard = React.memo(
  ({
    onClose,
    onSubmit,
    selectedDate,
    isCoach,
    contributionId,
    contributionTimeZoneId,
    serviceProviderName,
    duration,
    title,
    isSelectable,
    colorToUse,
    color,
    activeTemplate,
    selectedTimeZone,
    contribution,
    shouldHidePriceSection,
    easyBooking = false,
    anyPaymentOptionEnabled,
    userSelectedTimeZone,
    rescheduleSession = false,
    setRescheduleSession,
    isModal = false,
  }) => {
    const theme = useTheme();
    const borderRad = activeTemplate === TemplateType.TemplateOne ? '12px' : '5px';
    const [rescheduleError, setRescheduleError] = useState(false);
    const [showRescheduleReason, setShowRescheduleReason] = useState(false);
    const easyBookingThankYou = useSelector(state => state.easyBookingThankYou);
    const pickAnotherTime = useSelector(state => state.contributions.pickAnotherTime);
    const formerBookingData = useSelector(state => state?.contributions.formerBookingData);
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const currentContribution = useContribution();
    const [rescheduleReason, setRescheduleReason] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [showCountryDropDown, setShowCountryDropDown] = useState(false);
    const [disableBtn, setDisableBtn] = useState(true);
    const [coachPhoneNumber, setCoachPhoneNumber] = useState('');
    const [coachPhoneNumberFocused, setCoachPhoneNumberFocused] = useState(false);
    const { coachLoginInfo, currentRole, user } = useAccount();
    const isCohealer = currentRole === UserRoles.cohealer;
    const canvasRef = useRef();
    const dispatch = useDispatch();
    const phoneInputRef = useRef(null);
    const { customToS } = useContribution();
    const { pathname, location, query } = useRouter();
    const isMasterCalendar = pathname.includes('calendar/master');
    const urlParams = new URLSearchParams(location.search);
    const sessionId = urlParams.get('sessionId');
    const [showUnableJoin, setshowUnableJoin] = useState(false);
    const { newThemedCardColor, newThemedTextColor, newThemedBackgroundColor } = getThemedColors(
      isMasterCalendar ? null : currentContribution,
    );
    const guestTimeZone = useSelector(state => state.contributions.selectedGuestTimeZone);
    const priceId = useSelector(state => state.contributions.priceCode);
    const clientPackage = currentContribution.progressBarPackagePurchases?.find(p => p.userId === user?.id);
    const { sessionNumbers, freeSessionNumbers, bookedSessionNumbers } = clientPackage || {};
    const reschedulePopUp = useSelector(state => state.contributions.reschedulePopUp);
    const [smsReminderCheckBox, setSmsReminderCheckBox] = useState(true);
    const [showPhoneNumberFields, setshowPhoneNumberFields] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [loginStep, setloginStep] = useState(false);
    const [phoneNumberField, setPhoneNumberField] = useState('');
    const [showTerms, setShowTerms] = useState(false);
    const PrimaryColor = colorToUse?.PrimaryColorCode;
    const [signature, setSignature] = useState(null);
    const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
    const isCodeToJoin = useSelector(state => state?.contributions?.singleCode);
    const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
    const [selfBookData, setSelfBookData] = useState(null);
    const [waiverData, setWaiverData] = useState(null);
    const [defaultCountry, setDefaultCountry] = useState();
    const [isWaiverVisible, setIsWaiverVisible] = useState(false);
    const [error, setError] = useState(false);
    const [isSigned, setIsSigned] = useState(false);
    const request = isCoach ? getCoachContributionTimes : getClientContributionTimes;
    const [showEnrollmentForm, setShowEnrollmentForm] = useState(false);
    const [enrollmentFormSubmitted, setEnrollmentFormSubmitted] = useState(false);
    const firstRender = useRef(true);
    const { AccentColorCode = '' } = determineColorToUse(isMasterCalendar ? null : currentContribution);
    const [timezone, setTimezone] = useState(userSelectedTimeZone);
    const [countryState, setCountryState] = useState(user.countryId);
    const [stateId, setStateId] = useState(user.stateCode);
    const [selectedTimezone, setSelectedTimezone] = useState(userSelectedTimeZone || contribution?.timeZoneId);
    const { states } = useSelector(state => state?.states);
    const [timeZoneArray, setTimeZoneArray] = useState([]);
    const [statesArray, setStatesArray] = useState([]);
    const { countries } = useSelector(state => state?.country);
    const { fetchUserCountryCode } = useDefaultTimezone();
    const [phoneInputKey, setPhoneInputKey] = useState(0);

    const { newThemedBackgroundColor: themedBackgroundColor, newThemedTextColor: themedColor } = getThemedColors(
      isMasterCalendar ? null : contribution,
      false,
    );
    useEffect(() => {
      setTimezone(userSelectedTimeZone);
      const filteredTimezone = timeZones.filter(item => item?.countryName === userSelectedTimeZone);
      getTimezoneBasedTimes(contribution?.sessionIncrements?.[0] || 0, filteredTimezone[0]?.countryName);
    }, [userSelectedTimeZone]);

    const setDefaultCountryCode = async () => {
      const countryCode = await fetchUserCountryCode(selectedTimezone, timeZones, countries);
      setDefaultCountry(countryCode);
    };

    const toggleRescheduleSlotPopup = () => {
      setRescheduleError(!rescheduleError);
    };

    useEffect(() => {
      if (timeZones?.length > 0 && countries?.length > 0) setDefaultCountryCode();
    }, [selectedTimezone, timeZones, countries]);

    const applyFocusEventOnPhonInput = () => {
      if (phoneInputRef.current) {
        const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
        if (phoneInput) {
          phoneInput.addEventListener('focus', () => {
            setCoachPhoneNumberFocused(true);
          });
        }
        return phoneInput;
      }
      return null;
    };

    useEffect(() => {
      if (phoneInputRef.current && !phoneNumberField) {
        const phoneInput = applyFocusEventOnPhonInput();
        return () => {
          if (phoneInput) {
            phoneInput.removeEventListener('focus', () => {
              setCoachPhoneNumberFocused(false);
              setCoachPhoneNumber('');
            });
          }
        };
      }
      if (phoneNumberField) {
        setCoachPhoneNumberFocused(true);
        setCoachPhoneNumber(phoneNumberField);
      }
    }, [phoneInputRef.current, showPhoneNumberFields, smsReminderCheckBox]);

    useEffect(() => {
      setPhoneInputKey(phoneInputKey + 1);
    }, [defaultCountry]);

    useEffect(() => {
      applyFocusEventOnPhonInput();
    }, [phoneInputKey]);

    useEffect(() => {
      if (coachPhoneNumberFocused && phoneInputRef.current) {
        const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
        if (phoneInput) {
          phoneInput.focus();
        }
      }
    }, [coachPhoneNumberFocused]);

    useEffect(() => {
      if (phoneInputRef.current && !phoneNumberField) {
        const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
        if (phoneInput) {
          phoneInput.addEventListener('focus', () => {
            setCoachPhoneNumberFocused(true);
          });
        }
        return () => {
          if (phoneInput) {
            phoneInput.removeEventListener('focus', () => {
              setCoachPhoneNumberFocused(false);
              setCoachPhoneNumber('');
            });
          }
        };
      }
      if (phoneNumberField) {
        setCoachPhoneNumberFocused(true);
        setCoachPhoneNumber(phoneNumberField);
      }
    }, [phoneInputRef.current, showPhoneNumberFields, smsReminderCheckBox]);

    useEffect(() => {
      if (coachPhoneNumberFocused && phoneInputRef.current) {
        const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
        if (phoneInput) {
          phoneInput.focus();
        }
      }
    }, [coachPhoneNumberFocused]);

    useEffect(() => {
      if (contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null) {
        let filteredObjects = [];
        setCountryState(user.countryId);
        setTimezone(user.timeZoneId);
        setStateId(user.stateCode);
        filteredObjects = states.filter(obj => obj.countryId === user.countryId);

        // if (user.countryId != null) {
        if (filteredObjects.length > 0) {
          setStatesArray(filteredObjects);
        } else {
          setTimeZoneArray(timeZones);
        }
        // checkCountry();
      }
      // }
    }, [user, states]);
    const styledProps = {
      cardBackgroundColor: newThemedCardColor,
      color: themedColor,
      backgroundColor: themedBackgroundColor,
    };
    const classNames = useStyles(styledProps);
    useEffect(() => {
      const disablePaste = e => {
        e.preventDefault();
      };

      if ((!timeZones || timeZones.length === 0) && !timeZoneLoading) {
        fetchTimeZones();
      }
      if (!countries || !countries.length) {
        dispatch(countryActions.fetchCountries());
      }
      if (!states || !states.length) {
        dispatch(countryActions.fetchStates());
      }
    }, [timeZones]);
    useEffect(() => {
      const filteredObjects = states.filter(obj => obj.countryId === user.countryId);
      setTimezone(user.timeZoneId);
      if (filteredObjects.length > 0) {
        setStatesArray(filteredObjects);
      } else {
        setTimeZoneArray(timeZones);
      }
    }, [user, states]);

    const textColor =
      colorToUse?.TextColorCode === 'Auto'
        ? lightOrDark(colorToUse?.PrimaryColorCode)
        : colorToUse?.TextColorCode === '#000000'
        ? '#000000'
        : '#FFFFFF';

    useEffect(() => {
      if (isCodeToJoin) {
        getSelfBookData();
      }
      if (!isEmpty(user)) {
        if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
          if (
            !isEmpty(user.subCategorySmsPermissions) &&
            !isEmpty(contribution.subCategorySmsPermissionsPerContribution) &&
            ((user?.subCategorySmsPermissions?.OneHourSession === true &&
              contribution?.subCategorySmsPermissionsPerContribution?.OneHourSession === true) ||
              (user?.subCategorySmsPermissions?.TwentyFourHourSession === true &&
                contribution?.subCategorySmsPermissionsPerContribution?.TwentyFourHourSession === true))
          ) {
            setshowPhoneNumberFields(true);
          }
        } else {
          setshowPhoneNumberFields(false);
        }
      }
    }, []);

    const getSelfBookData = () => {
      getOneToOneSelfBookClient(isCodeToJoin.slice(2)).then(response => {
        setSelfBookData(response);
      });
    };
    const getTimezoneBasedTimes = async (offset, timezoneid) => {
      let concatedArray = [];
      if (isCohealer) {
        Promise.allSettled(
          [0].map(k => getCoachContributionTimes(contributionId, k, selectedDate.format('YYYY-MM-DD'), timezoneid)),
        ).then(_slots => {
          const zeroSlots = _slots.reduce(
            (acc, curr) => (acc.some(k => k.startTime === curr.value?.startTime) ? acc : [...acc, ...curr.value]),
            [],
          );
          const formattedSlots = zeroSlots
            .filter(slot => moment(slot.startTime).isSame(selectedDate._d, 'day'))
            .sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'));

          const events = getTimePeriodsForAvailability({
            availabilityPeriods: uniqBy(formattedSlots, 'id'),
            duration: '60',
            serviceProviderName: 'test',
          });
          setSlots(events);
          setLoading(false);
          const firstFreeSlot = events.find(s => !s.isBooked && isCurrentDateTimeBeforeSelected(s.start) === false);
          setSelectedSlot(firstFreeSlot);
        });
      } else {
        if (currentContribution.bufferTimeAfter > 0 || currentContribution.bufferTimeBefore > 0) {
          axiosInstance
            .post(
              `/Contribution/${contributionId}/GetClientSlotsWithTimezone?offset=${offset}&timezoneId=${timezoneid}&slotsStartDate=${moment(
                selectedDate,
              ).format('YYYY-MM-DD')}&slotsEndDate=${moment(selectedDate).format('YYYY-MM-DD')}`,
            )
            .then(zeroSlots => {
              const events = getTimePeriodsForAvailability({
                availabilityPeriods: uniqBy(zeroSlots.data, 'id'),
                duration,
                serviceProviderName,
              });
              setLoading(false);
              setSlots(events);
              const firstFreeSlot = events.find(s => !s.isBooked && isCurrentDateTimeBeforeSelected(s.start) === false);
              setSelectedSlot(firstFreeSlot);
            });
        } else {
          axiosInstance
            .post(
              `/Contribution/${contributionId}/GetClientSlotsWithTimezone?offset=${offset}&timezoneId=${timezoneid}&slotsStartDate=${moment(
                selectedDate,
              ).format('YYYY-MM-DD')}&slotsEndDate=${moment(selectedDate).format('YYYY-MM-DD')}`,
            )
            .then(zeroSlots => {
              const events = getTimePeriodsForAvailability({
                availabilityPeriods: uniqBy(zeroSlots.data, 'id'),
                duration,
                serviceProviderName,
              });
              setLoading(false);
              setSlots(events);
              const firstFreeSlot = events.find(s => !s.isBooked && isCurrentDateTimeBeforeSelected(s.start) === false);
              setSelectedSlot(firstFreeSlot);
            });
        }
      }
    };

    const clearSign = () => {
      if (canvasRef?.current) {
        canvasRef.current.clear();
        setDisableBtn(true);
      }
    };
    // useEffect(() => {
    //   getTimezoneBasedTimes(30, contribution?.timeZoneId);
    // }, []);

    const [slots, setSlots] = useState([]);
    const [timeZone, setTimeZone] = useState('');
    const [colorForSelect, setColorForSelect] = useState('darkgrey');
    const [loading, setLoading] = useState(true);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [btnDisabled, setbtnDisabled] = useState(false);
    const easyBookingData = useSelector(state => state?.easyBooking);
    function isCountryNameAvailable(countryName, arrayOfObjects) {
      return arrayOfObjects.some(obj => obj.countryName === countryName);
    }

    const checkCountry = async () => {
      if (contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null) {
        const res = await fetch('https://geolocation-db.com/json/');
        const data = await res.json();
        const currentCountry = countries.find(obj => obj.name === data.country_name);
        // const currentCountry = { id: '60b8ddb57205057e7ce2b861' }
        if (currentCountry?.id != user?.countryId) {
          if (currentCountry?.id === '60b8ddb57205057e7ce2b861') {
            setCountryState(currentCountry?.id);
            setStateId(null);
            setTimezone(null);
            const filteredObjects = states.filter(obj => obj.countryId === currentCountry?.id);
            const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry?.id);
            setStatesArray(filteredObjects);
            setTimeZoneArray(filteredTimezone);

            return false;
          } else {
            if ((countryState === null || countryState.length === 0) && (timezone === null || timezone.length === 0)) {
              return false;
            } else {
              const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry?.id);
              setTimeZoneArray(filteredTimezone);
              let checkTimeZone = isCountryNameAvailable(timezone, filteredTimezone);
              if (checkTimeZone) {
                return true;
              } else if (timezone != null || timezone.length > 0) {
                let checkTimeZoneValue = isCountryNameAvailable(timezone, filteredTimezone);
                if (checkTimeZoneValue) {
                  return true;
                } else {
                  setTimezone(null);
                  return false;
                }
              } else {
                setTimezone(null);
                return false;
              }
            }
          }
        } else {
          if (user?.countryId === '60b8ddb57205057e7ce2b861') {
            setCountryState(user.countryId);
            setTimezone(user.timeZoneId);
            setStateId(user.stateCode);
            const filteredStates = states.filter(obj => obj.countryId === user?.countryId);
            const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry?.id);
            setStatesArray(filteredStates);
            setTimeZoneArray(filteredTimezone);
            if (
              user?.timeZoneId != null &&
              user?.timeZoneId?.length != 0 &&
              user?.stateCode != null &&
              user?.stateCode?.length != 0 &&
              countryState != null &&
              countryState.lenght != 0 &&
              stateId.lenght != 0 &&
              stateId != null
            ) {
              return true;
            }
            return false;
          } else {
            const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
            setTimeZoneArray(filteredTimezone);
            let checkTimeZone = isCountryNameAvailable(timezone, filteredTimezone);
            if (checkTimeZone) {
              return true;
            } else if (timezone != null || timezone.length > 0) {
              let checkTimeZoneValue = isCountryNameAvailable(timezone, filteredTimezone);
              if (checkTimeZoneValue) {
                return true;
              } else {
                setTimezone(null);
                return false;
              }
            } else {
              setTimezone(null);
              return false;
            }
          }
        }
      }
    };

    const formattedTime = selectedSlot
      ? `${moment(selectedSlot.start).format(DATE_FORMATS.DATE_TIME_NO_LEADING_ZEROS)} - ${moment(
          selectedSlot.end,
        ).format(DATE_FORMATS.TIME_12_NO_LEADING_ZEROS)}`
      : '';
    const useSelectStyle = makeStyles(() => ({
      select: {
        width: '100%',
        color: colorForSelect,
        backgroundColor: 'white',
        borderRadius: '20px',
        border: '1px solid #f5f0f0',
        '& .MuiSelect-select:focus': {
          backgroundColor: 'white',
        },
      },
      email_main_container: {
        width: '100%',
      },
      email_main: {
        width: '100%',
      },
      email_root: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        color: 'black',
      },
      email_root_dark: {
        border: '1px solid rgba(255, 255, 255)',
        color: 'white',
      },
      email_notchedOutline: {
        border: 'none',
      },
    }));
    const selectStyle = useSelectStyle();

    useEffect(() => {
      if (selectedSlot === undefined) {
        dispatch(setEasyBookingThankYouData.setData({ ...formerBookingData }));
        dispatch(setEasyBookingData.setData({ ...formerBookingData }));
      } else {
        dispatch(setEasyBookingData.setData({ ...easyBookingData, selectedSlot: selectedSlot || '' }));
        dispatch(setEasyBookingThankYouData.setData({ ...easyBookingData, selectedSlot: selectedSlot || '' }));
      }
    }, [selectedSlot]);

    useEffect(() => {
      dispatch(
        setEasyBookingData.setData({
          ...easyBookingData,
          timeZone: timeZone || contribution?.timeZoneId,
          timeZoneThankYou: TIMEZONES[timeZone] || TIMEZONES[contribution?.timeZoneId],
        }),
      );
    }, [timeZone, contribution]);

    useEffect(() => {
      if (timeZones?.length > 0) {
        return;
      }
      if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
        dispatch(fetchTimeZones());
      }
    }, [timeZones, timeZoneLoading]);

    //     useEffect(() => {
    //       // const increments = contribution?.sessionIncrements || [];
    //       //const sessionDuration = contribution?.durations?.[0];
    //       const startTimeIncrementDuration = contribution?.sessionIncrements?.[0];
    //       const increments = [startTimeIncrementDuration];
    //       // if (startTimeIncrementDuration > 0) {
    //       //   for (let i = 0; i < Math.ceil(sessionDuration / startTimeIncrementDuration); i++) {
    //       //     increments.push(i * startTimeIncrementDuration);
    //       //   }
    //       // }
    //       // if (increments.every(k => k !== 0)) {
    //       //   increments.push(0);
    //       // }
    //       if (currentContribution.bufferTimeAfter > 0 || currentContribution.bufferTimeBefore > 0) {
    //         Promise.allSettled(
    //           increments.map(k =>
    //             request(
    //               contributionId,
    //               k,
    //               moment(selectedDate._d).format('YYYY-MM-DD'),
    //               moment(selectedDate._d).format('YYYY-MM-DD'),
    //             ),
    //           ),
    //         ).then(_slots => {
    //           const zeroSlots = _slots.reduce(
    //             (acc, curr) => (acc.some(k => k.startTime === curr.value?.startTime) ? acc : [...acc, ...curr.value]),
    //             [],
    //           );
    //           const events = getTimePeriodsForAvailability({
    //             availabilityPeriods: uniqBy(zeroSlots, 'id'),
    //             duration,
    //             serviceProviderName,
    //           });
    //           // setTimeout(() => {
    //           setLoading(false);
    //           setSlots(events);
    //           // }, 7000);
    //           const firstFreeSlot = events.find(s => !s.isBooked);
    //           const isBefore = isCurrentDateTimeBeforeSelected(firstFreeSlot?.start);
    //           if (!isBefore) {
    //             setSelectedSlot(firstFreeSlot);
    //           }
    //         });
    //       } else {
    //         Promise.allSettled(
    //           increments.map(k =>
    //             request(
    //               contributionId,
    //               k,
    //               moment(selectedDate._d).format('YYYY-MM-DD'),
    //               moment(selectedDate._d).format('YYYY-MM-DD'),
    //             ),
    //           ),
    //         ).then(_slots => {
    //           const zeroSlots = _slots.reduce(
    //             (acc, curr) => (acc.some(k => k.startTime === curr.value?.startTime) ? acc : [...acc, ...curr.value]),
    //             [],
    //           );

    //           const events = getTimePeriodsForAvailability({
    //             availabilityPeriods: uniqBy(zeroSlots, 'id'),
    //             duration,
    //             serviceProviderName,
    //           });
    //           console.info('events-33', selectedDate?._d, events);
    //           // setTimeout(() => {
    //           setLoading(false);
    //           setSlots(events);
    //           // }, 7000);
    //           const firstFreeSlot = events.find(s => !s.isBooked);
    //           const isBefore = isCurrentDateTimeBeforeSelected(firstFreeSlot?.start);
    //           if (!isBefore) {
    //             setSelectedSlot(firstFreeSlot);
    //           }
    //         });
    //       }
    //     }, [selectedDate]);

    const getWaiverForm = async () => {
      setLoading(true);
      // try {
      getSingleContract(currentContribution?.id)
        .then(data => {
          setWaiverData({
            ...waiverData,
            formName: data?.formName,
            formDescription: data?.formDescription,
            formText: data?.formText,
            description: data?.formDescription,
            templateId: data.id,
            id: data.id,
            completeDescription: data?.formText,
          });
          setIsWaiverVisible(true);
          setLoading(false);
          return;
        })
        .catch(() => {
          setLoading(false);
        });
      // } catch (e) {
      //   setLoading(false);
      // }
      // setLoading(false);
    };

    const saveUserSignature = async values => {
      setLoading(true);
      const formData = new FormData();
      const sign = dataURLtoFile(values.clientSignature);
      setWaiverData({
        ...waiverData,
        clientName: values.clientName,
      });
      formData.append('file', sign);
      setIsSigned(true);
      setIsWaiverVisible(false);
      setLoading(false);
    };

    const buttons = [];
    const titleText = isSelectable
      ? 'Select your session time'
      : 'These are the session times your clients can self-book';

    useEffect(() => {
      if (slots.length === 0) {
        setbtnDisabled(true);
      } else {
        setbtnDisabled(false);
      }
    }, [slots]);

    const isSignSaved = async signature => {
      let isSignedSubmitted = null;
      if (currentContribution.customWaiverId != null) {
        isSignedSubmitted = await submitUserSignature(signature?.clientSignature || signature);
      } else {
        isSignedSubmitted = await saveSignature();
      }
      return isSignedSubmitted;
    };

    const submitUserSignature = async signature => {
      let isSignedSubmitted = false;
      setLoading(true);
      const formData = new FormData();
      const sign = dataURLtoFile(signature);
      formData.append('file', sign);
      const userIP = await getIpGlobal();
      const details = {
        contributionId: contribution.id,
        contractId: contribution.customWaiverId,
        ipAddress: userIP,
      };
      try {
        setSignedContract(formData, details).then(data => {
          isSignedSubmitted = true;
        });
      } catch (e) {
        isSignedSubmitted = false;
      }
      setLoading(false);
      return isSignedSubmitted;
    };
    const saveSignature = async val => {
      const formData = new FormData();
      const sign = dataURLtoFile(signature);
      formData.append('file', sign);
      const userIP = await getIpGlobal();
      await saveSignoffData(formData, currentContribution.id, userIP)
        .then(res => {
          if (res === 'Signoff data succesfully saved.') {
          }
        })
        .catch(err => {});
    };

    const checkPhoneNumber = () => {
      if (!isEmpty(user)) {
        if (!isEmpty(user.globalSmsPermissions) && user.globalSmsPermissions.SessionReminder === true) {
          if (
            !isEmpty(user.subCategorySmsPermissions) &&
            !isEmpty(contribution.subCategorySmsPermissionsPerContribution) &&
            ((user?.subCategorySmsPermissions?.OneHourSession === true &&
              contribution?.subCategorySmsPermissionsPerContribution?.OneHourSession === true) ||
              (user?.subCategorySmsPermissions?.TwentyFourHourSession === true &&
                contribution?.subCategorySmsPermissionsPerContribution?.TwentyFourHourSession === true))
          ) {
            if (smsReminderCheckBox === true) {
              if (user?.phoneNo === null || user.phoneNo?.length === 0) {
                if (phoneNumberField.length === 0) {
                  setshowPhoneNumberFields(true);
                  return false;
                } else {
                  if (user?.phoneNo != phoneNumberField) {
                    updatePhoneNumber();
                  }
                  setshowPhoneNumberFields(false);
                  return true;
                }
              } else {
                if (user?.phoneNo != phoneNumberField && phoneNumberField.length > 0) {
                  setShowErrorMessage(false);
                  updatePhoneNumber();
                } else if (phoneNumberField.length > 0) {
                  setShowErrorMessage(false);
                  setshowPhoneNumberFields(true);
                  return true;
                } else {
                  setShowErrorMessage(true);
                  setshowPhoneNumberFields(true);
                  return false;
                }

                setshowPhoneNumberFields(false);
                return true;
              }
            } else {
              return true;
            }
          } else {
            setshowPhoneNumberFields(false);
            return true;
          }
        } else {
          setshowPhoneNumberFields(false);
          return true;
        }
      } else {
        if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
          setshowPhoneNumberFields(true);
        } else {
          setshowPhoneNumberFields(false);
        }

        return true;
      }
    };
    const updatePhoneNumber = async () => {
      await request('/Account/UpdatePhoneNo', 'POST', {
        phoneNumber: phoneNumberField,
      }).then(res => {
        let u = {
          ...user,
          phoneNo: phoneNumberField,
        };
        dispatch(updateUser(u));
      });
    };
    const onConfirm = async () => {
      if (
        contribution?.smsPermissionsPerContribution?.SessionReminder === true &&
        (freeSessionNumbers === 0 || freeSessionNumbers === undefined)
      ) {
        const phoneNumberAdded = checkPhoneNumber();
        if (!phoneNumberAdded) {
          setshowPhoneNumberFields(true);
          return;
        }
      }
      if (
        showCountryDropDown === false &&
        contribution.paymentType === 'Simple' &&
        !isEmpty(user) &&
        contribution.taxType != 'No' &&
        isInviteToJoin === null &&
        reschedulePopUp === false
      ) {
        if (
          (contribution.paymentInfo.paymentOptions.length === 1 &&
            contribution.paymentInfo.paymentOptions.includes('Free')) ||
          (contribution.paymentInfo.paymentOptions.length === 1 &&
            contribution.paymentInfo.paymentOptions.includes('FreeSessionsPackage')) ||
          (contribution.paymentInfo.paymentOptions.length === 2 &&
            contribution.paymentInfo.paymentOptions.includes('FreeSessionsPackage') &&
            contribution.paymentInfo.paymentOptions.includes('Free'))
        ) {
          onSubmit(selectedSlot);
        } else {
          const isCheckCountry = await checkCountry();
          if (!isCheckCountry) {
            setShowCountryDropDown(true);
            return;
          } else {
            if (countryState != null && countryState?.length > 0) {
              if (countryState === '60b8ddb57205057e7ce2b861') {
                if (stateId.length > 0 && stateId != null && timezone != null) {
                  const updatedUser = {
                    ...user,
                    stateCode: stateId,
                    timeZoneId: timezone,
                    countryId: countryState,
                  };
                  await editUserProfile(user.id, updatedUser).then(async res => {
                    let u = {
                      ...user,
                      countryId: res.user.countryId,
                      timeZoneId: res.user.timeZoneId,
                      stateCode: res.user.stateCode,
                    };
                    dispatch(updateUser(u));
                    if (user?.id === contribution?.userId) {
                      // setshowUnableJoin(true);
                      return false;
                    }
                    if (
                      (contribution?.isCustomEnrollmentFormAdded ||
                        (contribution?.isPhoneNumberAdded &&
                          contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
                      !enrollmentFormSubmitted &&
                      !isEmpty(user) &&
                      !isCodeToJoin &&
                      currentContribution?.isElectronicSignatureActive != true &&
                      currentContribution.customWaiverId === null
                    ) {
                      setShowEnrollmentForm(true);
                      return;
                    }
                    if (
                      (contribution?.isCustomEnrollmentFormAdded ||
                        (contribution?.isPhoneNumberAdded &&
                          contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
                      !enrollmentFormSubmitted &&
                      !isCodeToJoin
                    ) {
                      if (isEmpty(user)) {
                        onSubmit(selectedSlot);
                        return;
                      } else {
                        setShowEnrollmentForm(true);
                        return;
                      }
                    }
                    if (
                      enrollmentFormSubmitted &&
                      currentContribution?.isElectronicSignatureActive === true &&
                      currentContribution.customWaiverId != null &&
                      !isEmpty(user) &&
                      !isCodeToJoin &&
                      reschedulePopUp === false &&
                      !currentContribution?.isPurchased
                    ) {
                      getWaiverForm();
                      return;
                    }
                    if (
                      isInviteToJoin &&
                      !isEmpty(user) &&
                      currentContribution?.isElectronicSignatureActive === true &&
                      currentContribution.customWaiverId === null
                    ) {
                      saveSignature();
                      return;
                    }
                    if (
                      isInviteToJoin &&
                      !isEmpty(user) &&
                      currentContribution?.isElectronicSignatureActive === true &&
                      currentContribution.customWaiverId != null &&
                      reschedulePopUp === false
                    ) {
                      getWaiverForm();
                      return;
                    }
                    if (isCodeToJoin && !isEmpty(user)) {
                      updateTimeforSelfBook({
                        availabilityTimeId: selfBookData.availabilityTimeId,
                        id: isCodeToJoin.slice(2),
                        contributionId,
                        clientId: user?.id || selfBookData.clientId,
                        selectedSlotStartDateTime: selectedSlot.start,
                        selectedSlotEndDateTime: selectedSlot.end,
                        updatedAvailabilityTimeId: selectedSlot.id,
                        accessCode: selfBookData.accessCode,
                        clientName: selfBookData.clientName,
                        isNewUser: false,
                        otherTimePicked: true,
                      }).then(() => {
                        onSubmit(selectedSlot);
                        return;
                      });
                    } else {
                      if (
                        currentContribution.customWaiverId === null &&
                        location.pathname.includes('apply-contribution-view') === false
                      ) {
                        onSubmit(selectedSlot);
                        return;
                      } else if (
                        location.pathname.includes('apply-contribution-view') &&
                        currentContribution.customWaiverId != null
                      ) {
                        getWaiverForm();
                        return;
                      }
                    }

                    if (isEmpty(user)) {
                      onSubmit(selectedSlot);
                      return;
                    } else if (!isEmpty(user) && currentContribution.customWaiverId === null) {
                      onSubmit(selectedSlot);

                      return;
                    } else if (freeSessionNumbers > 0) {
                      onSubmit(selectedSlot);
                      return;
                    } else if (!isEmpty(user) && currentContribution.customWaiverId != null) {
                      getWaiverForm();
                    }
                    selectedTimeZone(timeZone);
                  });
                } else {
                  setError(true);
                }
              } else {
                if (timezone != null && timezone?.length > 0) {
                  const updatedUser = {
                    ...user,
                    stateCode: stateId,
                    timeZoneId: timezone,
                    countryId: countryState,
                  };
                  await editUserProfile(user.id, updatedUser).then(async res => {
                    let u = {
                      ...user,
                      countryId: res.user.countryId,
                      timeZoneId: res.user.timeZoneId,
                      stateCode: res.user.stateCode,
                    };
                    dispatch(updateUser(u));
                    if (user?.id === contribution?.userId) {
                      // setshowUnableJoin(true);
                      return false;
                    }
                    if (
                      (contribution?.isCustomEnrollmentFormAdded ||
                        (contribution?.isPhoneNumberAdded &&
                          contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
                      !enrollmentFormSubmitted &&
                      !isEmpty(user) &&
                      !isCodeToJoin &&
                      currentContribution?.isElectronicSignatureActive != true &&
                      currentContribution.customWaiverId === null
                    ) {
                      setShowEnrollmentForm(true);
                      return;
                    }
                    if (
                      (contribution?.isCustomEnrollmentFormAdded ||
                        (contribution?.isPhoneNumberAdded &&
                          contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
                      !enrollmentFormSubmitted &&
                      !isCodeToJoin
                    ) {
                      if (isEmpty(user)) {
                        onSubmit(selectedSlot);
                        return;
                      } else {
                        setShowEnrollmentForm(true);
                        return;
                      }
                    }
                    if (
                      enrollmentFormSubmitted &&
                      currentContribution?.isElectronicSignatureActive === true &&
                      currentContribution.customWaiverId != null &&
                      !isEmpty(user) &&
                      !isCodeToJoin &&
                      reschedulePopUp === false &&
                      !currentContribution?.isPurchased
                    ) {
                      getWaiverForm();
                      return;
                    }
                    if (
                      isInviteToJoin &&
                      !isEmpty(user) &&
                      currentContribution?.isElectronicSignatureActive === true &&
                      currentContribution.customWaiverId === null
                    ) {
                      saveSignature();
                      return;
                    }
                    if (
                      isInviteToJoin &&
                      !isEmpty(user) &&
                      currentContribution?.isElectronicSignatureActive === true &&
                      currentContribution.customWaiverId != null &&
                      reschedulePopUp === false
                    ) {
                      getWaiverForm();
                      return;
                    }
                    if (isCodeToJoin && !isEmpty(user)) {
                      updateTimeforSelfBook({
                        availabilityTimeId: selfBookData.availabilityTimeId,
                        id: isCodeToJoin.slice(2),
                        contributionId,
                        clientId: user?.id || selfBookData.clientId,
                        selectedSlotStartDateTime: selectedSlot.start,
                        selectedSlotEndDateTime: selectedSlot.end,
                        updatedAvailabilityTimeId: selectedSlot.id,
                        accessCode: selfBookData.accessCode,
                        clientName: selfBookData.clientName,
                        isNewUser: false,
                        otherTimePicked: true,
                      }).then(() => {
                        onSubmit(selectedSlot);
                        return;
                      });
                    } else {
                      if (
                        currentContribution.customWaiverId === null &&
                        location.pathname.includes('apply-contribution-view') === false
                      ) {
                        onSubmit(selectedSlot);
                        return;
                      } else if (
                        location.pathname.includes('apply-contribution-view') &&
                        currentContribution.customWaiverId != null
                      ) {
                        getWaiverForm();
                        return;
                      }
                    }

                    if (isEmpty(user)) {
                      onSubmit(selectedSlot);
                      return;
                    } else if (!isEmpty(user) && currentContribution.customWaiverId === null) {
                      onSubmit(selectedSlot);

                      return;
                    } else if (freeSessionNumbers > 0) {
                      onSubmit(selectedSlot);
                      return;
                    } else if (!isEmpty(user) && currentContribution.customWaiverId != null) {
                      getWaiverForm();
                    }
                    selectedTimeZone(timeZone);
                  });
                } else {
                  setError(true);
                }
              }
            }
          }
        }
      } else if (
        showCountryDropDown &&
        contribution.paymentType === 'Simple' &&
        contribution.taxType != 'No' &&
        !isEmpty(user) &&
        isInviteToJoin === null
      ) {
        const isCheckCountry = await checkCountry();
        if (isCheckCountry) {
          if (countryState != null && countryState?.length > 0) {
            if (countryState === '60b8ddb57205057e7ce2b861') {
              if (stateId.length > 0 && stateId != null && timezone != null) {
                const updatedUser = {
                  ...user,
                  stateCode: stateId,
                  timeZoneId: timezone,
                  countryId: countryState,
                };
                await editUserProfile(user.id, updatedUser).then(async res => {
                  let u = {
                    ...user,
                    countryId: res.user.countryId,
                    timeZoneId: res.user.timeZoneId,
                    stateCode: res.user.stateCode,
                  };
                  dispatch(updateUser(u));
                  if (user?.id === contribution?.userId) {
                    // setshowUnableJoin(true);
                    return false;
                  }
                  if (
                    (contribution?.isCustomEnrollmentFormAdded ||
                      (contribution?.isPhoneNumberAdded &&
                        contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
                    !enrollmentFormSubmitted &&
                    !isEmpty(user) &&
                    !isCodeToJoin &&
                    currentContribution?.isElectronicSignatureActive != true &&
                    currentContribution.customWaiverId === null
                  ) {
                    setShowEnrollmentForm(true);
                    return;
                  }
                  if (
                    (contribution?.isCustomEnrollmentFormAdded ||
                      (contribution?.isPhoneNumberAdded &&
                        contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
                    !enrollmentFormSubmitted &&
                    !isCodeToJoin
                  ) {
                    if (isEmpty(user)) {
                      onSubmit(selectedSlot);
                      return;
                    } else {
                      setShowEnrollmentForm(true);
                      return;
                    }
                  }
                  if (
                    enrollmentFormSubmitted &&
                    currentContribution?.isElectronicSignatureActive === true &&
                    currentContribution.customWaiverId != null &&
                    !isEmpty(user) &&
                    !isCodeToJoin &&
                    reschedulePopUp === false &&
                    !currentContribution?.isPurchased
                  ) {
                    getWaiverForm();
                    return;
                  }
                  if (
                    isInviteToJoin &&
                    !isEmpty(user) &&
                    currentContribution?.isElectronicSignatureActive === true &&
                    currentContribution.customWaiverId === null
                  ) {
                    saveSignature();
                    return;
                  }
                  if (
                    isInviteToJoin &&
                    !isEmpty(user) &&
                    currentContribution?.isElectronicSignatureActive === true &&
                    currentContribution.customWaiverId != null &&
                    reschedulePopUp === false
                  ) {
                    getWaiverForm();
                    return;
                  }
                  if (isCodeToJoin && !isEmpty(user)) {
                    updateTimeforSelfBook({
                      availabilityTimeId: selfBookData.availabilityTimeId,
                      id: isCodeToJoin.slice(2),
                      contributionId,
                      clientId: user?.id || selfBookData.clientId,
                      selectedSlotStartDateTime: selectedSlot.start,
                      selectedSlotEndDateTime: selectedSlot.end,
                      updatedAvailabilityTimeId: selectedSlot.id,
                      accessCode: selfBookData.accessCode,
                      clientName: selfBookData.clientName,
                      isNewUser: false,
                      otherTimePicked: true,
                    }).then(() => {
                      onSubmit(selectedSlot);
                      return;
                    });
                  } else {
                    if (
                      currentContribution.customWaiverId === null &&
                      location.pathname.includes('apply-contribution-view') === false
                    ) {
                      onSubmit(selectedSlot);
                      return;
                    } else if (
                      location.pathname.includes('apply-contribution-view') &&
                      currentContribution.customWaiverId != null
                    ) {
                      getWaiverForm();
                      return;
                    }
                  }

                  if (isEmpty(user)) {
                    onSubmit(selectedSlot);
                    return;
                  } else if (!isEmpty(user) && currentContribution.customWaiverId === null) {
                    onSubmit(selectedSlot);

                    return;
                  } else if (freeSessionNumbers > 0) {
                    onSubmit(selectedSlot);
                    return;
                  } else if (!isEmpty(user) && currentContribution.customWaiverId != null) {
                    getWaiverForm();
                  }
                  selectedTimeZone(timeZone);
                });
              } else {
                setError(true);
              }
            } else {
              if (timezone != null && timezone?.length > 0) {
                const updatedUser = {
                  ...user,
                  stateCode: stateId,
                  timeZoneId: timezone,
                  countryId: countryState,
                };
                await editUserProfile(user.id, updatedUser).then(async res => {
                  let u = {
                    ...user,
                    countryId: res.user.countryId,
                    timeZoneId: res.user.timeZoneId,
                    stateCode: res.user.stateCode,
                  };
                  dispatch(updateUser(u));
                  if (user?.id === contribution?.userId) {
                    if (isModal || rescheduleSession) {
                      setShowRescheduleReason(true);
                    }
                    // setshowUnableJoin(true);
                    return false;
                  }
                  if (
                    (contribution?.isCustomEnrollmentFormAdded ||
                      (contribution?.isPhoneNumberAdded &&
                        contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
                    !enrollmentFormSubmitted &&
                    !isEmpty(user) &&
                    !isCodeToJoin &&
                    currentContribution?.isElectronicSignatureActive != true &&
                    currentContribution.customWaiverId === null
                  ) {
                    setShowEnrollmentForm(true);
                    return;
                  }
                  if (
                    (contribution?.isCustomEnrollmentFormAdded ||
                      (contribution?.isPhoneNumberAdded &&
                        contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
                    !enrollmentFormSubmitted &&
                    !isCodeToJoin
                  ) {
                    if (isEmpty(user)) {
                      onSubmit(selectedSlot);
                      return;
                    } else {
                      setShowEnrollmentForm(true);
                      return;
                    }
                  }
                  if (
                    enrollmentFormSubmitted &&
                    currentContribution?.isElectronicSignatureActive === true &&
                    currentContribution.customWaiverId != null &&
                    !isEmpty(user) &&
                    !isCodeToJoin &&
                    reschedulePopUp === false &&
                    !currentContribution?.isPurchased
                  ) {
                    getWaiverForm();
                    return;
                  }
                  if (
                    isInviteToJoin &&
                    !isEmpty(user) &&
                    currentContribution?.isElectronicSignatureActive === true &&
                    currentContribution.customWaiverId === null
                  ) {
                    saveSignature();
                    return;
                  }
                  if (
                    isInviteToJoin &&
                    !isEmpty(user) &&
                    currentContribution?.isElectronicSignatureActive === true &&
                    currentContribution.customWaiverId != null &&
                    reschedulePopUp === false
                  ) {
                    getWaiverForm();
                    return;
                  }
                  if (isCodeToJoin && !isEmpty(user)) {
                    updateTimeforSelfBook({
                      availabilityTimeId: selfBookData.availabilityTimeId,
                      id: isCodeToJoin.slice(2),
                      contributionId,
                      clientId: user?.id || selfBookData.clientId,
                      selectedSlotStartDateTime: selectedSlot.start,
                      selectedSlotEndDateTime: selectedSlot.end,
                      updatedAvailabilityTimeId: selectedSlot.id,
                      accessCode: selfBookData.accessCode,
                      clientName: selfBookData.clientName,
                      isNewUser: false,
                      otherTimePicked: true,
                    }).then(() => {
                      onSubmit(selectedSlot);
                      return;
                    });
                  } else {
                    if (
                      currentContribution.customWaiverId === null &&
                      location.pathname.includes('apply-contribution-view') === false
                    ) {
                      onSubmit(selectedSlot);
                      return;
                    } else if (
                      location.pathname.includes('apply-contribution-view') &&
                      currentContribution.customWaiverId != null
                    ) {
                      getWaiverForm();
                      return;
                    }
                  }

                  if (isEmpty(user)) {
                    onSubmit(selectedSlot);
                    return;
                  } else if (!isEmpty(user) && currentContribution.customWaiverId === null) {
                    onSubmit(selectedSlot);

                    return;
                  } else if (freeSessionNumbers > 0) {
                    onSubmit(selectedSlot);
                    return;
                  } else if (!isEmpty(user) && currentContribution.customWaiverId != null) {
                    getWaiverForm();
                  }
                  selectedTimeZone(timeZone);
                });
              } else {
                setError(true);
              }
            }
          }
        }

        //  if (showCountryDropDown === true && contribution.paymentType === 'Simple' &&
        //   contribution.taxType != 'No') {
      } else {
        if (user?.id === contribution?.userId) {
          if (isModal || rescheduleSession) {
            setShowRescheduleReason(true);
          }
          // setshowUnableJoin(true);
          return false;
        }
        if (
          (contribution?.isCustomEnrollmentFormAdded ||
            (contribution?.isPhoneNumberAdded && contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
          !enrollmentFormSubmitted &&
          !isEmpty(user) &&
          !isCodeToJoin &&
          currentContribution?.isElectronicSignatureActive != true &&
          currentContribution.customWaiverId === null
        ) {
          setShowEnrollmentForm(true);
          return;
        }
        if (
          (contribution?.isCustomEnrollmentFormAdded ||
            (contribution?.isPhoneNumberAdded && contribution?.enrollmentFormViewModel?.questions?.length > 0)) &&
          !enrollmentFormSubmitted &&
          !isCodeToJoin
        ) {
          if (isEmpty(user)) {
            onSubmit(selectedSlot);
            return;
          } else {
            setShowEnrollmentForm(true);
            return;
          }
        }
        if (
          enrollmentFormSubmitted &&
          currentContribution?.isElectronicSignatureActive === true &&
          currentContribution.customWaiverId != null &&
          !isEmpty(user) &&
          !isCodeToJoin &&
          reschedulePopUp === false &&
          !currentContribution?.isPurchased
        ) {
          getWaiverForm();
          return;
        }
        if (
          isInviteToJoin &&
          !isEmpty(user) &&
          currentContribution?.isElectronicSignatureActive === true &&
          currentContribution.customWaiverId === null
        ) {
          saveSignature();
          return;
        }
        if (
          isInviteToJoin &&
          !isEmpty(user) &&
          currentContribution?.isElectronicSignatureActive === true &&
          currentContribution.customWaiverId != null &&
          reschedulePopUp === false
        ) {
          getWaiverForm();
          return;
        }
        if (isCodeToJoin && !isEmpty(user)) {
          updateTimeforSelfBook({
            availabilityTimeId: selfBookData.availabilityTimeId,
            id: isCodeToJoin.slice(2),
            contributionId,
            clientId: user?.id || selfBookData.clientId,
            selectedSlotStartDateTime: selectedSlot.start,
            selectedSlotEndDateTime: selectedSlot.end,
            updatedAvailabilityTimeId: selectedSlot.id,
            accessCode: selfBookData.accessCode,
            clientName: selfBookData.clientName,
            isNewUser: false,
            otherTimePicked: true,
          }).then(() => {
            if (currentContribution.customWaiverId === null) {
              onSubmit(selectedSlot);
              return;
            }
            return;
          });
        } else {
          if (
            currentContribution.customWaiverId === null &&
            location.pathname.includes('apply-contribution-view') === false
          ) {
            onSubmit(selectedSlot);
            return;
          } else if (
            location.pathname.includes('apply-contribution-view') &&
            currentContribution.customWaiverId != null
          ) {
            getWaiverForm();
            return;
          }
        }

        if (isEmpty(user)) {
          onSubmit(selectedSlot);
          return;
        } else if (!isEmpty(user) && currentContribution.customWaiverId === null) {
          onSubmit(selectedSlot);

          return;
        } else if (freeSessionNumbers > 0) {
          onSubmit(selectedSlot);
          return;
        } else if (!isEmpty(user) && currentContribution.customWaiverId != null) {
          if (currentContribution?.isPurchased === false) {
            getWaiverForm();
          } else {
            onSubmit(selectedSlot);
          }
        }
        selectedTimeZone(timeZone);
      }
    };

    useEffect(() => {
      if (contribution?.isPurchased) {
        setEnrollmentFormSubmitted(true);
        setTimeout(() => {
          firstRender.current = false;
        }, 200);
      }
    }, [contribution]);

    useEffect(() => {
      if (enrollmentFormSubmitted && (!firstRender.current || !contribution?.isPurchased)) {
        if (currentContribution?.isElectronicSignatureActive === true && currentContribution.customWaiverId === null) {
          getWaiverForm();
        } else {
          onConfirm();
        }
      }
    }, [enrollmentFormSubmitted]);

    function isCurrentDateTimeBeforeSelected(selectedDateTime) {
      const currentDateTime = moment();
      const selectedDate = moment(selectedDateTime);
      const currentDate = currentDateTime.isSame(selectedDate, 'day');
      if (currentDate) {
        return !currentDateTime.isBefore(selectedDate);
      } else {
        return false;
      }
    }

    return (
      <>
        {!showEnrollmentForm && (
          <div
            style={{
              marginTop: '0px',
              justifyContent: 'space-between',
              minHeight: '60vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MainContainer>
              <div
                className="one-one-session-slot-card"
                style={{
                  backgroundColor: newThemedCardColor,
                  padding: !isModal ? '10px 25px' : '0px 25px',
                }}
              >
                {showRescheduleReason === false ? (
                  <>
                    {isModal || rescheduleSession ? (
                      <></>
                    ) : (
                      <div
                        className="single-session-back-button"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginTop: mobileView ? '19px' : '',
                        }}
                      >
                        <div style={{ cursor: 'pointer' }} onClick={onClose}>
                          <b>{`< Back`}</b>
                          <b className="back-for-easy-booking">
                            <BackSvg color={!isModal ? 'rgb(40, 43, 43)' : AccentColorCode} />
                          </b>
                        </div>
                      </div>
                    )}
                    {isSelectable && !showCountryDropDown && (
                      <div style={{ marginTop: isModal || rescheduleSession ? '15px' : '' }}>
                        <div
                          style={
                            isModal || rescheduleSession
                              ? { display: 'flex', marginLeft: isModal || rescheduleSession ? '' : '15px' }
                              : {}
                          }
                        >
                          <StyledHeading
                            isModal={isModal}
                            className="easy-booking-session-time-title"
                            style={{
                              color: !isModal ? themedColor : AccentColorCode,
                            }}
                          >
                            Select Your Session Time
                          </StyledHeading>
                        </div>
                        <div className="slot-selection-description">
                          <p
                            style={{
                              fontSize: '14px',
                              marginTop: '15px',
                              marginBottom: '25px',
                              marginLeft: '10px',
                              textAlign: 'center',
                            }}
                          >
                            Please select your session time for {title} with <b>{serviceProviderName}</b>
                          </p>
                        </div>
                      </div>
                    )}
                    {!showCountryDropDown && (
                      <div className="slot-selection-description" style={{ display: 'flex', justifyContent: 'center' }}>
                        <p
                          style={{
                            // marginTop: '25px',
                            // marginBottom: '5px',
                            // fontWeight: '600',
                            // fontSize: '13px',
                            color: !isMasterCalendar && contribution.isDarkModeEnabled ? 'white' : '#282B2B',
                            fontFamily: 'Avenir',
                            fontSize: '1rem',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            textAlign: 'center',
                          }}
                        >
                          {formattedTime}
                        </p>
                      </div>
                    )}
                    {!showCountryDropDown && (
                      <div className="easy-booking-timezone" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                          className={
                            !isMasterCalendar && contribution.isDarkModeEnabled
                              ? 'darkThemeEnabled'
                              : 'whiteThemeEnabled'
                          }
                          style={{ width: '70%' }}
                        >
                          {easyBooking && (
                            <div
                              style={{
                                color: easyBookingData?.selectedSlot ? AccentColorCode : '',
                                fontFamily: 'Brandon Text',
                                fontSize: '17px',
                                fontWeight: 600,
                              }}
                            >
                              Time Zone
                            </div>
                          )}
                          <StyledSelectTz
                            iconColor={!isMasterCalendar && contribution?.isDarkModeEnabled ? newThemedTextColor : ''}
                            required
                            native
                            labelId="timezones"
                            isModal={isModal || rescheduleSession}
                            className={
                              isModal || rescheduleSession ? '' : `${selectStyle.select} easy-booking-timezone-select`
                            }
                            name="timezones"
                            onChange={e => {
                              dispatch(setGuestTimeZone(e.target.value));
                              setLoading(true);
                              setTimeZone(e.target.value);
                              setSelectedTimezone(e.target.value);
                              const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                              setColorForSelect(color);
                              const filteredTimezone = timeZones.filter(item => item?.countryName === e.target.value);
                              getTimezoneBasedTimes(
                                contribution?.sessionIncrements?.[0] || 0,
                                filteredTimezone[0]?.countryName,
                              );
                            }}
                            disableUnderline
                            style={
                              isModal || rescheduleSession
                                ? {
                                    // padding: '0px 15px',
                                    width: '100%',
                                    marginTop: '10px',
                                    position: 'relative',
                                    backgroundColor: newThemedBackgroundColor,
                                    color: newThemedTextColor,
                                  }
                                : {
                                    padding: '0px 15px',
                                    position: 'relative',
                                    backgroundColor: newThemedBackgroundColor,
                                    color: newThemedTextColor,
                                  }
                            }
                            value={selectedTimezone}
                          >
                            <option
                              aria-label="Time Zone"
                              value={null}
                              style={{ backgroundColor: newThemedBackgroundColor, color, newThemedTextColor }}
                            >
                              Select Time Zone
                            </option>
                            {timeZones?.length > 0 &&
                              timeZones.map(timeZone => (
                                <option
                                  style={{ backgroundColor: newThemedBackgroundColor, color, newThemedTextColor }}
                                  value={timeZone?.countryName}
                                >
                                  {timeZone?.name}
                                </option>
                              ))}
                          </StyledSelectTz>
                        </div>
                      </div>
                    )}
                    {loading && <Loader withoutTopOffset />}
                    {!showCountryDropDown && (
                      <StyledSlotContainer>
                        {/* {console.info('slots2:', slots, orderBy(slots, k => moment(k.start), 'asc'), orderBy(slots, k => moment(k.start), 'desc'))} */}
                        {orderBy(slots, k => moment(k.start), 'asc').map((slot, index) => {
                          const isSelectedSlot = selectedSlot && selectedSlot.id === slot.id;
                          const isBefore = isCurrentDateTimeBeforeSelected(slot.start);
                          return (
                            !isBefore && (
                              <StyledTimeSlots
                                className="easy-booking-slots"
                                mobileView={mobileView}
                                key={slot.id}
                                isBooked={slot.isBooked}
                                onClick={() => {
                                  if (!slot.isBooked) {
                                    setSelectedSlot(slot);
                                  }
                                }}
                                style={isSelectedSlot ? {} : { backgroundColor: newThemedBackgroundColor }}
                                isSelected={isSelectedSlot}
                                colorForSelected={colorToUse?.PrimaryColorCode}
                              >
                                <StyledTimeSlotValue
                                  style={isSelectedSlot ? { color: textColor } : { color: newThemedTextColor }}
                                >
                                  {moment(slot.start).format(DATE_FORMATS.TIME_12)}
                                </StyledTimeSlotValue>
                              </StyledTimeSlots>
                            )
                          );
                        })}
                        {!loading && (btnDisabled || selectedSlot === null) && (
                          <p
                            className="no-slot-selected"
                            style={{ fontSize: '14px', marginTop: '15px', marginBottom: '25px' }}
                          >
                            No times available
                          </p>
                        )}
                      </StyledSlotContainer>
                    )}
                    {showCountryDropDown && reschedulePopUp === false && (
                      <>
                        {!isEmpty(user) && (
                          <Grid item md={12} xs={12} style={{ padding: '5px 20px' }}>
                            {contribution.paymentType === 'Simple' &&
                              contribution.taxType != 'No' &&
                              isInviteToJoin === null && (
                                <>
                                  <Grid
                                    container
                                    item
                                    direction="column"
                                    alignItems="flex-start"
                                    md={12}
                                    xs={12}
                                    style={{
                                      padding: '5px 5px',
                                      position: 'relative',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography style={{ color: themedColor }} variant="h6">
                                      Select your Country & State
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} className={selectStyle.handleNewFormFieldsTimeZone}>
                                    <Select
                                      label="Country of Residence"
                                      name={ACCOUNT_FORM_FIELDS.country}
                                      fullWidth
                                      required
                                      native
                                      labelId="country"
                                      value={countryState}
                                      onChange={e => {
                                        const filteredObjects = states.filter(obj => obj.countryId === e.target.value);
                                        const filteredCountry = countries.find(obj => obj.id === e.target.value);
                                        const filteredTimezone = timeZones.filter(
                                          obj => obj.countryId === e.target.value,
                                        );
                                        if (filteredObjects.length >= 1) {
                                          setStatesArray(filteredObjects);
                                        } else if (filteredObjects.length === 0) {
                                          setStatesArray(filteredObjects);
                                          if (filteredTimezone.length === 0) {
                                            setTimeZoneArray(timeZones);
                                          } else if (
                                            filteredTimezone.length === 1 &&
                                            filteredCountry.id != '60b8ddb57205057e7ce2b861'
                                          ) {
                                            setTimeZoneArray(filteredTimezone);
                                            setTimezone(filteredTimezone[0].countryName);
                                          } else {
                                            setTimeZoneArray(filteredTimezone);
                                          }
                                        }
                                        setStateId('');
                                        setCountryState(filteredCountry.id);
                                      }}
                                      disableUnderline
                                      className={[classNames.select, classNames.themedTextField].join(' ')}
                                      style={{
                                        padding: '15px',
                                        position: 'relative',
                                        backgroundColor: newThemedBackgroundColor,
                                        color: newThemedTextColor,
                                        borderRadius: '10px',
                                      }}
                                    >
                                      <option aria-label="Time Zone" value={null}>
                                        Select Country
                                      </option>
                                      {countries?.length > 0 &&
                                        countries.map(timeZone => <option value={timeZone.id}>{timeZone.name}</option>)}
                                    </Select>
                                  </Grid>
                                </>
                              )}
                            {contribution.paymentType === 'Simple' &&
                              contribution.taxType != 'No' &&
                              countryState === '60b8ddb57205057e7ce2b861' &&
                              isInviteToJoin === null &&
                              statesArray?.length > 1 && (
                                <>
                                  <Grid
                                    style={{ marginTop: '10px' }}
                                    item
                                    xs={12}
                                    className={selectStyle.handleNewFormFieldsTimeZone}
                                  >
                                    <Select
                                      label="State"
                                      name={ACCOUNT_FORM_FIELDS.state}
                                      fullWidth
                                      required
                                      native
                                      labelId="stateCode"
                                      value={stateId}
                                      className={[classNames.select, classNames.themedTextField].join(' ')}
                                      style={{
                                        padding: '15px',
                                        position: 'relative',
                                        backgroundColor: newThemedBackgroundColor,
                                        color: newThemedTextColor,
                                        borderRadius: '10px',
                                      }}
                                      onChange={e => {
                                        const filteredState = states.find(obj => obj.alpha2Code === e.target.value);
                                        const filteredObjects = timeZones.filter(obj =>
                                          obj.statesIds.includes(filteredState.id),
                                        );
                                        if (filteredObjects.length >= 1) {
                                          if (filteredObjects.length === 1) {
                                            setTimeZoneArray(filteredObjects);
                                            setTimezone(filteredObjects[0].countryName);
                                          } else {
                                            setTimezone(filteredObjects[0].countryName);
                                            setTimeZoneArray(filteredObjects);
                                          }
                                        } else {
                                          setTimeZoneArray(timeZones);
                                        }
                                        setStateId(filteredState.alpha2Code);
                                      }}
                                      disableUnderline
                                    >
                                      <option aria-label="Time Zone" value={null}>
                                        Select State
                                      </option>
                                      {statesArray?.length > 0 &&
                                        statesArray?.map(timeZone => (
                                          <option value={timeZone.alpha2Code}>{timeZone.name}</option>
                                        ))}
                                    </Select>
                                  </Grid>
                                </>
                              )}
                            {contribution.paymentType === 'Simple' &&
                              contribution.taxType != 'No' &&
                              isInviteToJoin === null &&
                              timeZoneArray.length > 1 && (
                                <>
                                  <Grid style={{ marginTop: '10px' }} item xs={12}>
                                    <Select
                                      required
                                      native
                                      labelId="timezones"
                                      name={ACCOUNT_FORM_FIELDS.timeZoneId}
                                      onChange={e => {
                                        dispatch(setGuestTimeZone(e.target.value));
                                        setTimezone(e.target.value);
                                        const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                                        setColorForSelect(color);
                                      }}
                                      value={timezone}
                                      // onBlur={handleBlur}
                                      disableUnderline
                                      className={[classNames.select, classNames.themedTextField].join(' ')}
                                      style={{
                                        padding: '15px',
                                        position: 'relative',
                                        backgroundColor: newThemedBackgroundColor,
                                        color: newThemedTextColor,
                                        borderRadius: '10px',
                                      }}
                                    >
                                      <option aria-label="Time Zone" value={null}>
                                        Select Timezone
                                      </option>
                                      {timeZoneArray?.length > 0 &&
                                        timeZoneArray.map(timeZone => (
                                          <option
                                            value={timeZone.countryName}
                                            style={{
                                              backgroundColor: newThemedBackgroundColor,
                                              color: newThemedTextColor,
                                            }}
                                          >
                                            {timeZone.name}
                                          </option>
                                        ))}
                                    </Select>
                                  </Grid>
                                </>
                              )}
                            {error && (
                              <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                {timezone === null && 'Please select Timezone'}
                                {stateId === null && 'Please select State'}
                                {countryState === null && 'Please select Country'}
                              </StyledError>
                            )}
                          </Grid>
                        )}
                      </>
                    )}

                    {/* {!showCountryDropDown && (
              <div className="slot-selection-description" style={{ display: 'flex', justifyContent: 'center' }}>
                <p
                  style={{
                    marginTop: '25px',
                    marginBottom: '5px',
                    fontWeight: '600',
                    fontSize: '13px',
                  }}
                >
                  {formattedTime}
                </p>
              </div>
            )}
            {!showCountryDropDown && (
              <div className="easy-booking-timezone" style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  className={contribution.isDarkModeEnabled ? 'darkThemeEnabled' : 'whiteThemeEnabled'}
                  style={{ width: '70%' }}
                >
                  {easyBooking && (
                    <p
                      style={{
                        color: easyBookingData?.selectedSlot ? AccentColorCode : '',
                      }}
                    >
                      Timezone
                    </p>
                  )}
                  <Select
                    required
                    native
                    labelId="timezones"
                    className={`${selectStyle.select} easy-booking-timezone-select`}
                    name="timezones"
                    onChange={e => {
                      console.info('tz:', e.target.value);
                      setLoading(true);
                      setTimeZone(e.target.value);
                      setSelectedTimezone(e.target.value);
                      const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                      setColorForSelect(color);
                      const filteredTimezone = timeZones.filter(item => item?.countryName === e.target.value);
                      getTimezoneBasedTimes(
                        contribution?.sessionIncrements?.[0] || 0,
                        filteredTimezone[0]?.countryName,
                      );
                    }}
                    disableUnderline
                    style={{
                      padding: '0px 15px',
                      position: 'relative',
                      backgroundColor: newThemedBackgroundColor,
                      color: newThemedTextColor,
                    }}
                    value={selectedTimezone}
                  >
                    <option
                      aria-label="Time Zone"
                      value={null}
                      style={{ backgroundColor: newThemedBackgroundColor, color, newThemedTextColor }}
                    >
                      Select Timezone
                    </option>
                    {timeZones?.length > 0 &&
                      timeZones.map(timeZone => (
                        <option
                          style={{ backgroundColor: newThemedBackgroundColor, color, newThemedTextColor }}
                          value={timeZone?.countryName}
                        >
                          {timeZone?.name}
                        </option>
                      ))}
                  </Select>
                </div>
              </div>
            )} */}
                    {showPhoneNumberFields && reschedulePopUp === false && (
                      <>
                        {!isEmpty(user) && showPhoneNumberFields === true && freeSessionNumbers == undefined && (
                          <>
                            <Grid
                              item
                              alignItems="flex-start"
                              md={12}
                              xs={12}
                              style={{
                                padding: '5px 15px',
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Checkbox
                                style={{ color: themedColor }}
                                id="create-contribution-agreement"
                                color="primary"
                                checked={smsReminderCheckBox}
                                onChange={() => {
                                  if (smsReminderCheckBox === true) {
                                    setSmsReminderCheckBox(false);
                                  } else {
                                    setSmsReminderCheckBox(true);
                                  }
                                  if (user?.phoneNo?.length > 0 && !isEmpty(user)) {
                                    setPhoneNumberField(user?.phoneNo);
                                  }
                                }}
                              />
                              <div style={{ fontFamily: 'Avenir', fontWeight: '300', fontSize: '14px' }}>
                                Receive SMS session reminders
                              </div>
                            </Grid>

                            {smsReminderCheckBox === true && showPhoneNumberFields === true && (
                              <Grid
                                container
                                direction="column"
                                alignItems="flex-start"
                                md={12}
                                xs={12}
                                style={{ padding: '5px 25px' }}
                              >
                                <div style={{ width: '100%' }} ref={phoneInputRef}>
                                  {defaultCountry && (
                                    <PhoneInput
                                      key={coachPhoneNumberFocused + phoneInputKey}
                                      value={coachPhoneNumber}
                                      defaultCountry={defaultCountry}
                                      {...(!coachPhoneNumberFocused && { disableDialCodePrefill: true })}
                                      {...(coachPhoneNumberFocused && { forceDialCode: true })}
                                      disableCountryGuess
                                      placeholder="Phone Number"
                                      onChange={phone => {
                                        setPhoneNumberField(phone);
                                        setCoachPhoneNumber(phone);
                                      }}
                                    />
                                  )}
                                  {/* {errors && errors[ACCOUNT_FORM_FIELDS?.phone] && touched[ACCOUNT_FORM_FIELDS?.phone] && (
                          <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                            {errors[ACCOUNT_FORM_FIELDS?.phone]}
                          </StyledError>
                        )} */}
                                  {showErrorMessage && (
                                    <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                      Please enter valid phone number
                                    </StyledError>
                                  )}
                                </div>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {isInviteToJoin &&
                      !isEmpty(user) &&
                      !contribution?.customWaiverId &&
                      !showCountryDropDown &&
                      currentContribution.isElectronicSignatureActive === true && (
                        <Grid item md={12} xs={12} style={{ padding: '5px 30px' }}>
                          <div style={{ position: 'relative' }}>
                            <SignatureCanvas
                              ref={canvasRef}
                              clearOnResize={false}
                              penColor="black"
                              canvasProps={{ height: '200px' }}
                              onEnd={() => {
                                setDisableBtn(false);
                                // setDisableValue(false);
                                if (canvasRef?.current) {
                                  const val = canvasRef?.current?.getTrimmedCanvas().toDataURL('image/png');
                                  setSignature(val);
                                }
                              }}
                            />
                            <div
                              type="button"
                              onClick={clearSign}
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '5px',
                                right: '10px',
                                // color: colors.darkOceanBlue,
                                fontWeight: 'bold',
                              }}
                            >
                              Clear
                            </div>
                          </div>
                          <p
                            style={{
                              marginBottom: '0px',
                              fontSize: '12px',
                              marginLeft: '5px',
                              // color: PrimaryColor,
                            }}
                          >
                            Sign Here To Join
                          </p>
                        </Grid>
                      )}
                    {!isEmpty(user) && contribution?.customWaiverId && !showCountryDropDown && (
                      <Typography style={{ color: newThemedTextColor }} variant="caption">
                        By clicking reserve, I agree to the{' '}
                        <StyledLink
                          style={{ color: PrimaryColor }}
                          href={'/'}
                          onClick={e => {
                            e.preventDefault();
                            setShowTerms(true);
                          }}
                        >
                          Terms and Conditions
                        </StyledLink>
                        , I also agree to {serviceProviderName}
                        {"'"}s{' '}
                        <StyledDiv color={PrimaryColor}>
                          {currentContribution?.customWaiverTemplateName?.trim()}
                        </StyledDiv>
                        , and I'm at least 18 years old.
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontWeight: '800',
                          fontSize: '20px',
                          color: AccentColorCode,
                        }}
                      >
                        {/* <ArrowBackIosIcon
                      onClick={e => {
                        setShowRescheduleReason(false);
                      }}
                      style={{ cursor: 'pointer', width: '14px', height: '14px' }}
                    /> */}
                        Enter Details
                      </div>
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontWeight: '500',
                          fontSize: '16px',
                          color: colorToUse.AccentColorCode,
                          marginTop: '20px',
                          marginBottom: '10px',
                        }}
                      >
                        Reason for reschedule:
                      </div>
                      <div className={selectStyle.email_main_container}>
                        <TextField
                          className={selectStyle.email_main}
                          name="otherReason"
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          value={rescheduleReason}
                          placeholder="Please share reason for reschedule"
                          multiline
                          minRows={6}
                          onChange={e => {
                            setRescheduleReason(e.target.value);
                          }}
                          FormHelperTextProps={{
                            style: {
                              textAlign: 'right',
                              marginRight: 0,
                            },
                          }}
                          InputProps={{
                            classes: {
                              root:
                                !isMasterCalendar && contribution.isDarkModeEnabled
                                  ? selectStyle.email_root_dark
                                  : selectStyle.email_root,
                              notchedOutline: selectStyle.email_notchedOutline,
                            },
                          }}
                        />
                      </div>
                    </div>
                    {showLoader && <Loader relative withoutTopOffset />}
                  </>
                )}
              </div>
            </MainContainer>
            <FooterContainer>
              <div
                className="easy-booking-slot-buttons"
                style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
              >
                <div style={{ width: '50%', marginBottom: '25px', display: 'flex', justifyContent: 'center' }}>
                  {isSelectable && (
                    <StyledButton
                      style={{
                        borderRadius: borderRad,
                        ...(pathname.includes('sessions') && {
                          backgroundColor:
                            !isMasterCalendar && contribution.isDarkModeEnabled ? newThemedBackgroundColor : 'white',
                          color: !isMasterCalendar && contribution.isDarkModeEnabled ? 'white' : 'black',
                          border: `1px solid ${
                            !isMasterCalendar && contribution.isDarkModeEnabled ? 'white' : 'black'
                          }`,
                        }),
                      }}
                      backgroundColor="darkgrey"
                      marginTop
                      activeTemplate={activeTemplate}
                      onClick={onClose}
                    >
                      {isModal || rescheduleSession ? 'Back' : 'Cancel'}
                    </StyledButton>
                  )}
                </div>
                <div style={{ width: '50%', marginBottom: '25px', display: 'flex', justifyContent: 'center' }}>
                  {rescheduleSession === true || (user?.id === contribution?.userId && isModal) ? (
                    <>
                      <StyledButtonPadded
                        type="button"
                        style={{ borderRadius: borderRad, color: textColor }}
                        backgroundColor={colorToUse?.PrimaryColorCode}
                        marginTop
                        activeTemplate={activeTemplate}
                        onClick={() => {
                          if (showRescheduleReason === false) {
                            if (user?.id != contribution?.userId) {
                              const availableToReschedule = moment(easyBookingData?.selectedSlot?.start).isAfter(
                                moment().add(24, 'h'),
                              );
                              if (!availableToReschedule) {
                                setRescheduleError(true);
                              } else {
                                setShowRescheduleReason(true);
                              }
                            } else {
                              setShowRescheduleReason(true);
                            }
                          } else {
                            setShowLoader(true);
                            let filteredBookedId;
                            if (
                              query.code === 'GuestUserReschedule' &&
                              formerBookingData.selectedSlot.id === sessionId
                            ) {
                              filteredBookedId = formerBookingData.selectedSlot.id;
                            } else {
                              if (
                                formerBookingData.isBookedTime === undefined ||
                                formerBookingData.isBookedTime === false
                              ) {
                                filteredBookedId = contribution.availabilityTimes.filter(
                                  item =>
                                    item.id === pickAnotherTime?.id || item.id === formerBookingData.selectedSlot.id,
                                )[0].bookedTimes[0]?.id;
                              } else {
                                filteredBookedId = formerBookingData.selectedSlot.id;
                              }
                            }

                            const rescheduleData = {
                              rescheduleFromId: filteredBookedId,
                              rescheduleToId: easyBookingData?.selectedSlot?.id,
                              contributionId: currentContribution.id,
                              note: rescheduleReason,
                              offset: easyBookingData?.selectedSlot?.offset,
                            };
                            if (user?.id === contribution?.userId && isModal) {
                              contributionService
                                .rescheduleSession(rescheduleData)
                                .then(res => {
                                  dispatch(
                                    setRescheduleSessionTimeIdForGuestCheckout(easyBookingData?.selectedSlot?.id),
                                  );
                                  let filteredData = res.availabilityTimes.filter(
                                    item => item.id === easyBookingData?.selectedSlot?.id,
                                  );
                                  dispatch(
                                    setEasyBookingThankYouData.setData({
                                      ...easyBookingData,
                                      helpingMaterial: getItem('helpingMaterial'),
                                    }),
                                  );

                                  dispatch(setRescheduleFormerSessionDetail({}));
                                  setRescheduleSession(false);
                                  dispatch(fetchCohealerContribution(currentContribution.id));
                                  setShowLoader(false);
                                })
                                .catch(() => {
                                  setShowLoader(false);
                                });
                            } else {
                              contributionService
                                .rescheduleSessionForClient({
                                  ...rescheduleData,
                                  bookingDoneWithSecondaryAccount: true,
                                  // guestClientId: user?.id,
                                  // guestTimeZone: guestTimeZone,
                                })
                                .then(res => {
                                  dispatch(
                                    setRescheduleSessionTimeIdForGuestCheckout(easyBookingData?.selectedSlot?.id),
                                  );
                                  let filteredData = res.availabilityTimes.filter(
                                    item => item.id === easyBookingData?.selectedSlot?.id,
                                  );
                                  if (query.code != 'GuestUserReschedule') {
                                    dispatch(
                                      setEasyBookingThankYouData.setData({
                                        ...easyBookingData,
                                        helpingMaterial: getItem('helpingMaterial'),
                                      }),
                                    );
                                  }
                                  dispatch(setRescheduleFormerSessionDetail({}));
                                  setRescheduleSession(false);
                                  setShowLoader(false);
                                })
                                .catch(() => {
                                  setShowLoader(false);
                                });
                            }
                          }
                        }}
                        disabled={
                          isInviteToJoin &&
                          !isEmpty(user) &&
                          currentContribution.customWaiverId === null &&
                          currentContribution.isElectronicSignatureActive === true &&
                          disableBtn === true
                            ? true
                            : !isSelectable || btnDisabled
                        }
                      >
                        {!showCountryDropDown &&
                        !isEmpty(user) &&
                        reschedulePopUp === false &&
                        showRescheduleReason === false
                          ? 'Next'
                          : 'Reschedule'}
                      </StyledButtonPadded>
                    </>
                  ) : (
                    <>
                      <StyledButton
                        style={{ borderRadius: borderRad, color: textColor }}
                        backgroundColor={colorToUse?.PrimaryColorCode}
                        marginTop
                        activeTemplate={activeTemplate}
                        onClick={onConfirm}
                        disabled={
                          isInviteToJoin &&
                          !isEmpty(user) &&
                          currentContribution.customWaiverId === null &&
                          currentContribution.isElectronicSignatureActive === true &&
                          disableBtn === true
                            ? true
                            : !isSelectable || btnDisabled
                        }
                      >
                        {isModal
                          ? 'Confirm'
                          : !showCountryDropDown && !isEmpty(user) && reschedulePopUp === false
                          ? 'Next'
                          : 'Confirm'}
                      </StyledButton>
                    </>
                  )}
                </div>
              </div>
            </FooterContainer>
          </div>
        )}
        {showEnrollmentForm && (
          <Grid item md={12} xs={12} style={{ ...(pathname.includes('sessions') ? { padding: '5px 30px' } : {}) }}>
            <EnrollmentForm
              enrollmentForm={contribution.enrollmentFormViewModel}
              colorToUse={colorToUse}
              contribution={contribution}
              activeTemplate={activeTemplate}
              style={{
                ...(pathname.includes('sessions')
                  ? { backgroundColor: newThemedBackgroundColor, padding: '0 25px' }
                  : {}),
              }}
              onSubmitSuccess={() => setEnrollmentFormSubmitted(true)}
              onClose={() => setShowEnrollmentForm(false)}
              selectedTimezone={selectedTimezone}
              timeZones={timeZones}
              countries={countries}
            />
          </Grid>
        )}
        {isWaiverVisible && (
          <CreateCustomWaiver
            isOpen={isWaiverVisible}
            onSubmit={values =>
              isSignSaved(values).then(() => {
                onSubmit(selectedSlot);
              })
            }
            contribution={contribution}
            waiverData={waiverData}
            isDarkModeAllowed={true}
            onCancel={() => setIsWaiverVisible(false)}
            isCreateContribution={false}
          />
        )}
        <ModalTermsAndConditions applyTheming showTerms={showTerms} onCancel={() => setShowTerms(false)} />
        {showUnableJoin && (
          <Modal
            title="Unable to join"
            isOpen={showUnableJoin}
            submitTitle="Close"
            hiddenCancel
            onCancel={() => {
              setshowUnableJoin(false);
            }}
            dontCancelOnSideClick
            onSubmit={() => {
              setshowUnableJoin(false);
            }}
            style={{ zIndex: '2000' }}
          >
            <p>Please use another email - you entered the account holder email of this service.</p>
          </Modal>
        )}
        {rescheduleError && (
          <Modal
            title="Reschedule Error"
            isOpen={true}
            onCloseText="OK"
            hiddenCancel
            onSubmit={toggleRescheduleSlotPopup}
            onCancel={toggleRescheduleSlotPopup}
            disableFooter
          >
            <div style={{ fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
              You can not book a session with less than 24 hours notice. Please choose a time slot that is at least 24
              hours in advance.
            </div>
          </Modal>
        )}
      </>
    );
  },
);

SlotsModalForPaymentCard.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  isCoach: PropTypes.bool,
  contributionId: PropTypes.string.isRequired,
  contributionTimeZoneId: PropTypes.string,
  serviceProviderName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  isSelectable: PropTypes.bool,
};

SlotsModalForPaymentCard.defaultProps = {
  isCoach: false,
  isSelectable: true,
};

export default SlotsModalForPaymentCard;
